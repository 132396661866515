import { Month0, Month60 } from "../Months/MonthsRange";

export const MonthStart = Month0;
export const MonthEnd = Month60;

export const threePercent = [
    2.5, 3.4, 4.4, 5.1, 5.6, 6.1, 6.4, 6.7, 7, 7.2, 7.5, 7.7, 7.8, 8, 8.2, 8.4, 8.5, 8.7, 8.9, 9, 9.2, 9.3, 9.5, 9.7,
    9.8, 10, 10.1, 10.2, 10.4, 10.5, 10.7, 10.8, 10.9, 11.1, 11.2, 11.3, 11.4, 11.6, 11.7, 11.8, 11.9, 12.1, 12.2,
    12.3, 12.4, 12.5, 12.7, 12.8, 12.9, 13, 13.1, 13.3, 13.4, 13.5, 13.6, 13.7, 13.8, 13.9, 14.1, 14.2, 14.3
];

export const fifteenPercent = [
    2.9, 3.9, 4.9, 5.6, 6.2, 6.7, 7.1, 7.4, 7.7, 7.9, 8.2, 8.4, 8.6, 8.8, 9, 9.2, 9.4, 9.6, 9.7, 9.9, 10.1, 10.3, 10.5,
    10.6, 10.8, 11, 11.1, 11.3, 11.5, 11.6, 11.8, 11.9, 12.1, 12.2, 12.4, 12.5, 12.7, 12.8, 12.9, 13.1, 13.2, 13.4,
    13.5, 13.6, 13.8, 13.9, 14.1, 14.2, 14.3, 14.5, 14.6, 14.7, 14.9, 15, 15.2, 15.3, 15.4, 15.6, 15.7, 15.8, 16
];

export const fiftyPercent = [
    3.3, 4.5, 5.6, 6.4, 7, 7.5, 7.9, 8.3, 8.6, 8.9, 9.2, 9.4, 9.6, 9.9, 10.1, 10.3, 10.5, 10.7, 10.9, 11.1, 11.3, 11.5,
    11.8, 12, 12.2, 12.4, 12.5, 12.7, 12.9, 13.1, 13.3, 13.5, 13.7, 13.8, 14, 14.2, 14.3, 14.5, 14.7, 14.8, 15, 15.2,
    15.3, 15.5, 15.7, 15.8, 16, 16.2, 16.3, 16.5, 16.7, 16.8, 17, 17.2, 17.3, 17.5, 17.7, 17.8, 18, 18.2, 18.3
];

export const eightyFivePercent = [
    3.9, 5.1, 6.3, 7.2, 7.9, 8.4, 8.9, 9.3, 9.6, 10, 10.3, 10.5, 10.8, 11.1, 11.3, 11.6, 11.8, 12, 12.3, 12.5, 12.7,
    13, 13.2, 13.4, 13.7, 13.9, 14.1, 14.4, 14.6, 14.8, 15, 15.2, 15.5, 15.7, 15.9, 16.1, 16.3, 16.5, 16.7, 16.9, 17.1,
    17.3, 17.5, 17.7, 17.9, 18.1, 18.3, 18.5, 18.7, 18.9, 19.1, 19.3, 19.5, 19.7, 19.9, 20.1, 20.3, 20.5, 20.7, 20.9,
    21.1
];

export const ninetySevenPercent = [
    4.3, 5.7, 7, 7.9, 8.6, 9.2, 9.7, 10.2, 10.5, 10.9, 11.2, 11.5, 11.8, 12.1, 12.4, 12.7, 12.9, 13.2, 13.5, 13.7, 14,
    14.3, 14.5, 14.8, 15.1, 15.3, 15.6, 15.9, 16.1, 16.4, 16.6, 16.9, 17.1, 17.3, 17.6, 17.8, 18, 18.3, 18.5, 18.7, 19,
    19.2, 19.4, 19.7, 19.9, 20.1, 20.4, 20.6, 20.9, 21.1, 21.3, 21.6, 21.8, 22.1, 22.3, 22.5, 22.8, 23, 23.3, 23.5, 23.8
];
