export const postLogin = async (formData) => {
    let authResponseDTO = null;
    await fetch(`${process.env.REACT_APP_URL_API}/api/login`, {
        body: JSON.stringify(formData),
        headers: {
            "Content-Type": "application/json",
            "Origin": "http://localhost:3000"
        },
        method: "POST"
    }).then(async response => {
        if (response.ok) {
            authResponseDTO = await response.json();
        } else if (response.status === 401) {
            alert(await response.text());
        }
    }).catch(error => {
        console.error("Erro na requisição:", error);
    });

    return authResponseDTO;
}

export const solicitarResetSenha = async (formData) => {
    return await fetch(`${process.env.REACT_APP_URL_API}/api/reset-senha/solicitar`, {
        body: JSON.stringify(formData),
        headers: {
            "Content-Type": "application/json",
            "Origin": process.env.REACT_APP_URL_API
        },
        method: "POST"
    }).then(async response => {
        
        const teste = await response.text()
        return teste;
        // return await response.text();
    }).catch(error => {
        console.error("Erro na requisição:", error);
    });
}

export const redefinirSenha = async (pathName, newPassword) => {
    await fetch(`${process.env.REACT_APP_URL_API}/api/reset-senha${pathName}/${newPassword}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(response => {
        if (response.ok) {
            alert("Senha atualizada com sucesso!");
            window.location.href = window.location.origin;
        }
    }).catch(error => {
        alert("Erro ao redefinir a senha: " + error);
    });
};

export const validarToken = async (pathName) => {
    await fetch(`${process.env.REACT_APP_URL_API}/api/reset-senha${pathName}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(response => {
        if (!response.ok) {
            alert("Token inválido.");
            window.location.href = window.location.origin;
        }
    }).catch(error => {
        alert("Token inválido: " + error);
    });
};