import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import Select from "react-select";
import EditarEventoModal from "./EditarEventoModal";

const AgendaPorMedicoModal = ({
    events,
    isModalOpen,
    onCloseModal,
    funcionarios,
    abrirModalParaEditarAtendimento
}) => {
    const [funcionarioSelecionado, setFuncionarioSelecionado] = useState(null);
    const [
        eventosDoFuncionarioSelecionado,
        setEventosDoFuncionarioSelecionado,
    ] = useState([]);

    const alterarEventosPorFuncionarioSelecionado = (e) => {
        setFuncionarioSelecionado(e);

        const eventosSelecionados = events.filter((event) => {
            return event.usuario.id === e.id;
        });

        setEventosDoFuncionarioSelecionado(eventosSelecionados);
    };
    const [eventInfo, setEventInfo] = useState([]);
    const [modalEditarAtendimentoOpen, setModalEditarAtendimentoOpen] =
        useState(false);

    return (
        <>
            {isModalOpen && (
                <div
                    id="top-right-modal"
                    data-modal-placement="top-right"
                    tabIndex="-1"
                    className="bg-black bg-opacity-50 fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] min-h-full justify-center items-start flex"
                >
                    <div className="relative w-full max-w-xl max-h-full">
                        {/* <!-- Modal content --> */}
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            {/* <!-- Modal header --> */}
                            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    Agendamento por profissional
                                </h3>
                                <button
                                    onClick={onCloseModal}
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    data-modal-hide="novo-evento-modal"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <div className="p-6 space-y-6">
                                <form>
                                    <div className="flex">
                                        <div className="relative w-full">
                                            <Select
                                                onChange={
                                                    alterarEventosPorFuncionarioSelecionado
                                                }
                                                value={funcionarioSelecionado}
                                                maxMenuHeight={150}
                                                options={funcionarios}
                                                placeholder="Selecione o médico"
                                                required
                                            />
                                            {/* <input name="dataDaConsulta" id="data_da_consulta" type="date" className="block w-full p-4 pr-20 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="DD/MM/YYYY" required /> */}
                                            {/* <button type="submit" className="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                                </svg>
                                                <span className="sr-only">Search</span>
                                            </button> */}
                                        </div>
                                    </div>
                                </form>
                                {/* <ListaConsultasFinalizadas consultasFinalizadas={consultasFinalizadas} /> */}
                                <FullCalendar
                                    height={600}
                                    locale={ptBrLocale}
                                    eventColor={"green"}
                                    plugins={[
                                        dayGridPlugin,
                                        interactionPlugin,
                                        timeGridPlugin,
                                        listPlugin,
                                    ]}
                                    initialView={"listMonth"}
                                    weekends={true}
                                    events={eventosDoFuncionarioSelecionado}
                                    eventClick={(info) => {
                                        abrirModalParaEditarAtendimento(info);
                                    }}
                                    selectable={false}
                                    timeZone="America/Sao_Paulo"
                                    dayMaxEventRows={2}
                                    headerToolbar={{
                                        right: "prev,next",
                                    }}
                                />
                            </div>
                            <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                <button
                                    onClick={onCloseModal}
                                    type="button"
                                    className="text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                    data-modal-hide="novo-evento-modal"
                                >
                                    Fechar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AgendaPorMedicoModal;
