import React, { useState, useEffect } from "react";
import TimeLinePaciente from "./TimeLinePaciente";
import { postConsulta } from "./ConsultaService";
import { alterarStatusAgendamento } from "./AgendaService";
import Graficos from "./Graficos";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";

const FichaDeAtendimentoModal = ({
    observacao,
    tipoConsulta,
    idAgenda,
    statusAgenda,
    token,
    authResponseDTO,
    funcionarioDoEventoSelecionado,
    consultasPorIdPaciente,
    paciente,
    isModalOpen,
    onCloseModal,
    consultasParaGraficosPorIdPaciente,
}) => {
    const [formData, setFormData] = useState({
        paciente: paciente,
        anamnese: {
            queixaPrincipal: "",
            historiaDaMolestiaAtual: "",
            revisaoDeSistemas: "",
            historiaPatologicaPregressa: "",
            historiaFamiliar: "",
            historiaSocial: "",
        },
        exameFisico: {
            exameFisico: "",
            peso: "",
            altura: "",
            perimetroCefalico: "",
        },
        exameComplementar: {
            exameComplementar: "",
        },
        conduta: {
            conduta: "",
        },
        usuario: authResponseDTO,
        resumoCompletoDoHistorico: "",
    });

    useEffect(() => {
        setFormData({
            ...formData,
            paciente: paciente,
            usuario: authResponseDTO,
        });
    }, [paciente, authResponseDTO, tipoConsulta]);

    const handleAnamneseChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            anamnese: {
                ...formData.anamnese,
                [name]: value,
            },
        });
    };

    const handleExameFisicoChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            exameFisico: {
                ...formData.exameFisico,
                [name]: value,
            },
        });
    };

    const handleExameComplementarChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            exameComplementar: {
                ...formData.exameComplementar,
                [name]: value,
            },
        });
    };

    const handleCondutaChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            conduta: {
                ...formData.conduta,
                [name]: value,
            },
        });
    };

    const salvarConsulta = () => {
        postConsulta(formData, token);
        alterarStatusAgendamento(idAgenda, token, "FINALIZADA");
    };

    const copiarDadosParaConsultaAtual = (consulta) => {
        setFormData({
            paciente: paciente,
            anamnese: {
                queixaPrincipal: consulta.anamnese.queixaPrincipal,
                historiaDaMolestiaAtual:
                    consulta.anamnese.historiaDaMolestiaAtual,
                revisaoDeSistemas: consulta.anamnese.revisaoDeSistemas,
                historiaPatologicaPregressa:
                    consulta.anamnese.historiaPatologicaPregressa,
                historiaFamiliar: consulta.anamnese.historiaFamiliar,
                historiaSocial: consulta.anamnese.historiaSocial,
            },
            exameFisico: {
                exameFisico: consulta.exameFisico.exameFisico,
            },
            exameComplementar: {
                exameComplementar: consulta.exameComplementar.exameComplementar,
            },
            conduta: {
                conduta: consulta.conduta.conduta,
            },
            usuario: authResponseDTO,
            resumoCompletoDoHistorico: consulta.resumoCompletoDoHistorico,
        });
    };

    const tabs = [
        { id: 1, title: "Histórico de consultas" },
        { id: 2, title: "Anamnese" },
        { id: 3, title: "Exame físico" },
        { id: 4, title: "Exames complementares" },
        { id: 5, title: "Impressão e conduta" },
        { id: 6, title: "Observação" },
    ];
    const [activeTab, setActiveTab] = useState(tabs[0]);

    const formatarNumero = (inputValue) => {
        return (inputValue.replace(/\D/g, "") / 100).toFixed(2);
    };

    const handleNumeroChange = (event) => {
        const { name, value } = event.target;
        const numeroFormatado = formatarNumero(value);
        // setNumero(numeroFormatado);

        setFormData({
            ...formData,
            exameFisico: {
                ...formData.exameFisico,
                [name]: numeroFormatado,
            },
        });
    };

    return (
        <>
            {isModalOpen && (
                <div className="flex justify-center items-center min-h-screen">
                    <div
                        id="novo-evento-modal"
                        tabIndex="-1"
                        aria-hidden="true"
                        className="b-block modal absolute z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
                            <div className="relative w-full max-w-7xl max-h-full">
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                    <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                            Atendendo paciente
                                            <small>
                                                {" "}
                                                ({paciente.label},{" "}
                                                {paciente.idade}) - Tipo da
                                                consulta: {tipoConsulta}
                                            </small>
                                        </h3>
                                        <button
                                            onClick={onCloseModal}
                                            type="button"
                                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                            data-modal-hide="novo-evento-modal"
                                        >
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                            <span className="sr-only">
                                                Close modal
                                            </span>
                                        </button>
                                    </div>
                                    <div
                                        className="p-6 space-y-6 overflow-y-auto"
                                        style={{ height: "60vh" }}
                                    >
                                        <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
                                            <ul
                                                className="flex flex-wrap -mb-px text-sm font-medium text-center"
                                                id="myTab"
                                                data-tabs-toggle="#myTabContent"
                                                role="tablist"
                                            >
                                                {tabs.map((tab) => (
                                                    <li
                                                        key={tab.id}
                                                        onClick={() =>
                                                            setActiveTab(tab)
                                                        }
                                                        className={`mr-2`}
                                                    >
                                                        <button
                                                            className={`inline-block p-4 border-b-2 ${
                                                                activeTab.id ===
                                                                tab.id
                                                                    ? "text-blue-600"
                                                                    : "border-transparent"
                                                            } rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`}
                                                        >
                                                            {tab.title}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div id="myTabContent">
                                            <div
                                                className={`${
                                                    activeTab.id === 1
                                                        ? ""
                                                        : "hidden"
                                                }p-4 rounded-lg bg-gray-100 dark:bg-gray-800`}
                                            >
                                                <div
                                                    className={`${
                                                        activeTab.id === 1
                                                            ? ""
                                                            : "hidden"
                                                    } text-sm text-gray-500 dark:text-gray-400`}
                                                >
                                                    <TimeLinePaciente
                                                        copiarDadosParaConsultaAtual={
                                                            copiarDadosParaConsultaAtual
                                                        }
                                                        consultasPorIdPaciente={
                                                            consultasPorIdPaciente
                                                        }
                                                        authResponseDTO={
                                                            authResponseDTO
                                                        }
                                                        isCopiarDados={true}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={`${
                                                    activeTab.id === 2
                                                        ? ""
                                                        : "hidden"
                                                }p-4 rounded-lg bg-gray-100 dark:bg-gray-800`}
                                            >
                                                <div
                                                    className={
                                                        activeTab.id === 2
                                                            ? ""
                                                            : "hidden"
                                                    }
                                                >
                                                    <label
                                                        for="queixa-principal"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        Queixa principal (QP)
                                                    </label>
                                                    <textarea
                                                        onChange={
                                                            handleAnamneseChange
                                                        }
                                                        name="queixaPrincipal"
                                                        value={
                                                            formData.anamnese
                                                                .queixaPrincipal
                                                        }
                                                        id="queixa-principal"
                                                        rows="4"
                                                        className="mb-5 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Escreva aqui a queixa principal..."
                                                    ></textarea>

                                                    <label
                                                        for="historia-molestia-atual"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        História da Moléstia
                                                        Atual (HMA)
                                                    </label>
                                                    <textarea
                                                        onChange={
                                                            handleAnamneseChange
                                                        }
                                                        name="historiaDaMolestiaAtual"
                                                        value={
                                                            formData.anamnese
                                                                .historiaDaMolestiaAtual
                                                        }
                                                        id="historia-molestia-atual"
                                                        rows="4"
                                                        className="mb-5 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Escreva aqui a história da moléstia atual..."
                                                    ></textarea>

                                                    <label
                                                        for="revisao-sistemas"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        Revisão de Sistemas
                                                    </label>
                                                    <textarea
                                                        onChange={
                                                            handleAnamneseChange
                                                        }
                                                        name="revisaoDeSistemas"
                                                        value={
                                                            formData.anamnese
                                                                .revisaoDeSistemas
                                                        }
                                                        id="revisao-sistemas"
                                                        rows="4"
                                                        className="mb-5 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Escreva aqui a revisão de sistemas..."
                                                    ></textarea>

                                                    <label
                                                        for="historia-patologica-pregressa"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        História Patológica
                                                        Pregressa
                                                    </label>
                                                    <textarea
                                                        onChange={
                                                            handleAnamneseChange
                                                        }
                                                        name="historiaPatologicaPregressa"
                                                        value={
                                                            formData.anamnese
                                                                .historiaPatologicaPregressa
                                                        }
                                                        id="historia-patologica-pregressa"
                                                        rows="4"
                                                        className="mb-5 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Escreva aqui a história patológica pregressa..."
                                                    ></textarea>

                                                    <label
                                                        for="historia-familiar"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        História Familiar
                                                    </label>
                                                    <textarea
                                                        onChange={
                                                            handleAnamneseChange
                                                        }
                                                        name="historiaFamiliar"
                                                        value={
                                                            formData.anamnese
                                                                .historiaFamiliar
                                                        }
                                                        id="historia-familiar"
                                                        rows="4"
                                                        className="mb-5 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Escreva aqui a história familiar..."
                                                    ></textarea>

                                                    <label
                                                        for="historia-social"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        História Social
                                                    </label>
                                                    <textarea
                                                        onChange={
                                                            handleAnamneseChange
                                                        }
                                                        name="historiaSocial"
                                                        value={
                                                            formData.anamnese
                                                                .historiaSocial
                                                        }
                                                        id="historia-social"
                                                        rows="4"
                                                        className="mb-5 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Escreva aqui a história social..."
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div
                                                className={`${
                                                    activeTab.id === 3
                                                        ? ""
                                                        : "hidden"
                                                }p-4 rounded-lg bg-gray-100 dark:bg-gray-800`}
                                            >
                                                <div
                                                    className={`${
                                                        activeTab.id === 3
                                                            ? ""
                                                            : "hidden"
                                                    } text-sm text-gray-500 dark:text-gray-400`}
                                                >
                                                    <label
                                                        for="exame-fisico"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        Exame físico
                                                    </label>
                                                    <textarea
                                                        onChange={
                                                            handleExameFisicoChange
                                                        }
                                                        name="exameFisico"
                                                        value={
                                                            formData.exameFisico
                                                                .exameFisico
                                                        }
                                                        id="exame-fisico"
                                                        rows="4"
                                                        className="mb-5 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Escreva aqui o exame físico do paciente..."
                                                    ></textarea>
                                                    <div className="grid md:grid-cols-6 md:gap-6">
                                                        <div className="relative z-0 w-full group">
                                                            <label
                                                                for="exame-fisico-altura"
                                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                            >
                                                                Altura
                                                            </label>
                                                            <div className="flex">
                                                                <input
                                                                    type="text"
                                                                    pattern="\d*"
                                                                    maxLength={
                                                                        6
                                                                    }
                                                                    name="altura"
                                                                    id="exame-fisico-altura"
                                                                    className="rounded-none rounded-s-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                                    placeholder="Ex.: 42.5"
                                                                    value={
                                                                        formData
                                                                            .exameFisico
                                                                            .altura
                                                                    }
                                                                    onChange={
                                                                        handleNumeroChange
                                                                    }
                                                                />
                                                                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-md border-gray-300 rounded-s-0 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                                    cm
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <label
                                                                for="exame-fisico-peso"
                                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                            >
                                                                Peso
                                                            </label>
                                                            <div className="flex">
                                                                <input
                                                                    type="text"
                                                                    pattern="\d*"
                                                                    maxLength={
                                                                        5
                                                                    }
                                                                    name="peso"
                                                                    id="exame-fisico-peso"
                                                                    className="rounded-none rounded-s-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                                    placeholder="Ex.: 42.5"
                                                                    value={
                                                                        formData
                                                                            .exameFisico
                                                                            .peso
                                                                    }
                                                                    onChange={
                                                                        handleNumeroChange
                                                                    }
                                                                />
                                                                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-md border-gray-300 rounded-s-0 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                                    kg
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <label
                                                                for="exame-fisico-perimetroCefalico"
                                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                            >
                                                                Perímetro
                                                                cefálico
                                                            </label>
                                                            <div className="flex">
                                                                <input
                                                                    type="text"
                                                                    pattern="\d*"
                                                                    maxLength={
                                                                        5
                                                                    }
                                                                    name="perimetroCefalico"
                                                                    id="exame-fisico-perimetroCefalico"
                                                                    className="rounded-none rounded-s-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                                    placeholder="Ex.: 42.5"
                                                                    value={
                                                                        formData
                                                                            .exameFisico
                                                                            .perimetroCefalico
                                                                    }
                                                                    onChange={
                                                                        handleNumeroChange
                                                                    }
                                                                />
                                                                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-md border-gray-300 rounded-s-0 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                                    cm
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {consultasParaGraficosPorIdPaciente &&
                                                    consultasParaGraficosPorIdPaciente !==
                                                        null ? (
                                                        <Graficos
                                                            consultasDoPaciente={
                                                                consultasParaGraficosPorIdPaciente
                                                            }
                                                            permiteSelecionarPaciente={
                                                                false
                                                            }
                                                            paciente={paciente}
                                                            authResponseDTO={
                                                                authResponseDTO
                                                            }
                                                            token={token}
                                                            perimetroCefalico={
                                                                formData
                                                                    .exameFisico
                                                                    .perimetroCefalico
                                                            }
                                                            altura={
                                                                formData
                                                                    .exameFisico
                                                                    .altura
                                                            }
                                                            peso={
                                                                formData
                                                                    .exameFisico
                                                                    .peso
                                                            }
                                                            isModalDeAtendimento={
                                                                true
                                                            }
                                                        />
                                                    ) : (
                                                        <div className="mt-6 text-center">
                                                            Paciente não possui
                                                            dados essenciais
                                                            para que os gráficos
                                                            sejam exibidos.
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className={`${
                                                    activeTab.id === 4
                                                        ? ""
                                                        : "hidden"
                                                }p-4 rounded-lg bg-gray-100 dark:bg-gray-800`}
                                            >
                                                <div
                                                    className={`${
                                                        activeTab.id === 4
                                                            ? ""
                                                            : "hidden"
                                                    } text-sm text-gray-500 dark:text-gray-400`}
                                                >
                                                    <label
                                                        for="exames-complementares"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        Exames Complementares
                                                    </label>
                                                    <textarea
                                                        onChange={
                                                            handleExameComplementarChange
                                                        }
                                                        name="exameComplementar"
                                                        value={
                                                            formData
                                                                .exameComplementar
                                                                .exameComplementar
                                                        }
                                                        id="exames-complementares"
                                                        rows="4"
                                                        className="mb-5 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Escreva aqui os exames complementares..."
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div
                                                className={`${
                                                    activeTab.id === 5
                                                        ? ""
                                                        : "hidden"
                                                }p-4 rounded-lg bg-gray-100 dark:bg-gray-800`}
                                            >
                                                <div
                                                    className={`${
                                                        activeTab.id === 5
                                                            ? ""
                                                            : "hidden"
                                                    } text-sm text-gray-500 dark:text-gray-400`}
                                                >
                                                    <label
                                                        for="impressao-conduta"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        Impressão e Conduta
                                                    </label>
                                                    <textarea
                                                        onChange={
                                                            handleCondutaChange
                                                        }
                                                        name="conduta"
                                                        value={
                                                            formData.conduta
                                                                .conduta
                                                        }
                                                        id="impressao-conduta"
                                                        rows="4"
                                                        className="mb-5 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Escreva aqui a impressão e conduta..."
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div
                                                className={`${
                                                    activeTab.id === 6
                                                        ? ""
                                                        : "hidden"
                                                }p-4 rounded-lg bg-gray-100 dark:bg-gray-800`}
                                            >
                                                <div
                                                    className={`${
                                                        activeTab.id === 6
                                                            ? ""
                                                            : "hidden"
                                                    } text-sm text-gray-500 dark:text-gray-400`}
                                                >
                                                    <label
                                                        for="observacao"
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                        Observação
                                                    </label>
                                                    <textarea
                                                        disabled
                                                        name="observacao"
                                                        value={observacao}
                                                        id="observacao"
                                                        rows="4"
                                                        className="no-resize mb-5 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                        <button
                                            onClick={onCloseModal}
                                            type="button"
                                            className="text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                            data-modal-hide="novo-evento-modal"
                                        >
                                            Fechar
                                        </button>
                                        <button
                                            onClick={salvarConsulta}
                                            type="button"
                                            className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                        >
                                            Salvar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FichaDeAtendimentoModal;
