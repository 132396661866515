import { Month0, Month60 } from "../Months/MonthsRange";

export const MonthStart = Month0;
export const MonthEnd = Month60;

export const threePercent = [
    32.1, 35.1, 36.9, 38.3, 39.4, 40.3, 41, 41.7, 42.2, 42.6, 43, 43.4, 43.6, 43.9, 44.1, 44.3, 44.5, 44.7, 44.9, 45,
    45.2, 45.3, 45.4, 45.6, 45.7, 45.8, 45.9, 46, 46.1, 46.2, 46.3, 46.4, 46.5, 46.6, 46.6, 46.7, 46.8, 46.9, 46.9, 47,
    47, 47.1, 47.2, 47.2, 47.3, 47.3, 47.4, 47.4, 47.5, 47.5, 47.5, 47.6, 47.6, 47.7, 47.7, 47.7, 47.8, 47.8, 47.9,
    47.9, 47.9  
];

export const fifteenPercent = [
    33.1, 36.1, 37.9, 39.3, 40.4, 41.3, 42.1, 42.7, 43.2, 43.7, 44.1, 44.4, 44.7, 45, 45.2, 45.5, 45.6, 45.8, 46, 46.2,
    46.3, 46.4, 46.6, 46.7, 46.8, 47, 47.1, 47.2, 47.3, 47.4, 47.5, 47.6, 47.7, 47.8, 47.8, 47.9, 48, 48.1, 48.1, 48.2,
    48.3, 48.3, 48.4, 48.4, 48.5, 48.5, 48.6, 48.6, 48.7, 48.7, 48.8, 48.8, 48.9, 48.9, 49, 49, 49, 49.1, 49.1, 49.2,
    49.2
];

export const fiftyPercent = [
    34.5, 37.3, 39.1, 40.5, 41.6, 42.6, 43.3, 44, 44.5, 45, 45.4, 45.8, 46.1, 46.3, 46.6, 46.8, 47, 47.2, 47.4, 47.5,
    47.7, 47.8, 48, 48.1, 48.3, 48.4, 48.5, 48.6, 48.7, 48.8, 48.9, 49, 49.1, 49.2, 49.3, 49.4, 49.5, 49.5, 49.6, 49.7,
    49.7, 49.8, 49.9, 49.9, 50, 50.1, 50.1, 50.2, 50.2, 50.3, 50.3, 50.4, 50.4, 50.4, 50.5, 50.5, 50.6, 50.6, 50.7,
    50.7, 50.7
];

export const eightyFivePercent = [
    35.8, 38.5, 40.3, 41.7, 42.9, 43.8, 44.6, 45.3, 45.8, 46.3, 46.7, 47.1, 47.4, 47.7, 47.9, 48.2, 48.4, 48.6, 48.7,
    48.9, 49.1, 49.2, 49.4, 49.5, 49.7, 49.8, 49.9, 50, 50.2, 50.3, 50.4, 50.5, 50.6, 50.7, 50.8, 50.8, 50.9, 51, 51.1,
    51.2, 51.2, 51.3, 51.4, 51.4, 51.5, 51.6, 51.6, 51.7, 51.7, 51.8, 51.8, 51.9, 51.9, 52, 52, 52.1, 52.1, 52.2, 52.2,
    52.2, 52.3  
];

export const ninetySevenPercent = [
    36.9, 39.5, 41.3, 42.7, 43.9, 44.8, 45.6, 46.3, 46.9, 47.4, 47.8, 48.2, 48.5, 48.8, 49, 49.3, 49.5, 49.7, 49.9, 50,
    50.2, 50.4, 50.5, 50.7, 50.8, 50.9, 51.1, 51.2, 51.3, 51.4, 51.6, 51.7, 51.8, 51.9, 52, 52, 52.1, 52.2, 52.3, 52.4,
    52.4, 52.5, 52.6, 52.7, 52.7, 52.8, 52.8, 52.9, 53, 53, 53.1, 53.1, 53.2, 53.2, 53.3, 53.3, 53.4, 53.4, 53.5, 53.5,
    53.5
];
