import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import * as ALturaPorIdadeMenino from "./Helpers/AlturaPorIdade/boy";
import * as ALturaPorIdadeMenina from "./Helpers/AlturaPorIdade/girl";
import * as PesoPorIdadeMenino from "./Helpers/PesoPorIdade/boy";
import * as PesoPorIdadeMenina from "./Helpers/PesoPorIdade/girl";
import * as PerimetroCefalicoMenino from "./Helpers/PerimetroCefalico/boy";
import * as PerimetroCefalicoMenina from "./Helpers/PerimetroCefalico/girl";
import PesoPorIdade from "./Graficos/PesoPorIdade";
import PerimetroCefalicoPorIdade from "./Graficos/PerimetroCefalicoPorIdade";
import AlturaPorIdade from "./Graficos/AlturaPorIdade";
import { getPacientesByNome } from "./PacientesService";
import ConfiguracaoPacienteModal from "./ConfiguracaoPacienteModal";
import { getExamesFisicosPorIdPaciente } from "./ConsultaService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTableCellsLarge,
    faList,
    faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import { Dismiss } from "flowbite";

const Graficos = ({
    permiteSelecionarPaciente,
    paciente,
    consultasDoPaciente,
    authResponseDTO,
    token,
    perimetroCefalico,
    altura,
    peso,
    isModalDeAtendimento,
}) => {
    const [pacienteSelecionado, setPacienteSelecionado] = useState(null);
    const [pacientes, setPacientes] = useState([]);
    const [quantidadeDeColunas, setQuantidadeDeColunas] = useState(2);
    const [consultaDoPacienteSelecionado, setConsultaDoPacienteSelecionado] =
        useState(null);
    const [mensagem, setMensagem] = useState(
        "Digite pelo menos 3 caracteres...",
    );
    const [modalOpen, setModalOpen] = useState(false);

    const onModalClose = () => {
        setModalOpen(false);
    };

    const NoOptionsMessage = (props) => {
        return (
            <components.NoOptionsMessage {...props}>
                {mensagem}
            </components.NoOptionsMessage>
        );
    };

    const filtrarPaciente = (nomeDoPaciente) => {
        getPacientesByNome(token, nomeDoPaciente)
            .then((response) => {
                if (response && response.length <= 0) {
                    setMensagem("Nenhum paciente encontrado...");
                } else if (!response.error) {
                    setPacientes(response);
                }
            })
            .catch((error) => {
                console.error("Erro ao acessar a API:", error);
            });
    };

    const filtrarConsultaDoPacienteSelecionado = (idPacienteSelecionado) => {
        getExamesFisicosPorIdPaciente(idPacienteSelecionado, token)
            .then((response) => {
                if (response && response.length <= 0) {
                    setMensagem(
                        "Nenhuma consulta para este paciente encontrada...",
                    );
                } else if (!response.error) {
                    setConsultaDoPacienteSelecionado(response);
                }
            })
            .catch((error) => {
                const $targetEl = document.getElementById("alert-border-2");

                // optional trigger element
                const $triggerEl = document.getElementById(
                    "botao-fechar-alerta",
                );

                // options object
                const options = {
                    transition: "transition-opacity",
                    timing: "ease-out",

                    // callback functions
                    onHide: (context, targetEl) => {
                        console.log("element has been dismissed");
                        console.log(targetEl);
                    },
                };

                // instance options object
                const instanceOptions = {
                    id: "alert-border-2",
                    override: true,
                };

                const dismiss = new Dismiss(
                    $targetEl,
                    $triggerEl,
                    options,
                    instanceOptions,
                );

                setInterval(function () {
                    dismiss.hide();
                }, 3000);

                console.error("Erro ao acessar a API:", error);
            });
    };

    const editarPacienteSelecionado = (e) => {
        setPacienteSelecionado(e);
        if (e.sexo === "NAO_INFORMADO") {
            if (
                window.confirm(
                    "Para visualizar os gráficos é necessário informar o sexo do paciente. Gostaria de atualizar esta informação agora?",
                )
            ) {
                setModalOpen(true);
            } else {
                window.location.reload();
                return;
            }
        }

        filtrarConsultaDoPacienteSelecionado(e.id);
    };

    useEffect(() => {
        setConsultaDoPacienteSelecionado(consultasDoPaciente);
        setPacienteSelecionado(paciente);
    }, [consultasDoPaciente, paciente]);

    if (
        pacienteSelecionado &&
        pacienteSelecionado !== null &&
        pacienteSelecionado.sexo === "NAO_INFORMADO" &&
        isModalDeAtendimento
    ) {
        return (
            <>
                <div className="mt-6 text-center">
                    Para visualizar os gráficos de crescimento em tempo real
                    atualize o cadastro do paciente com a informação do 'sexo
                    biológico'.
                    <br />
                    <strong>
                        (disponível somente para pacientes até 5 anos de idade)
                    </strong>
                </div>
            </>
        );
    }

    return (
        <>
            {/* <section className="bg-gray-900 dark:bg-gray-900 p-3 sm:p-5 "> */}
                <div className="w-full p-5 lg:px-12 bg-gray-900">
                    <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                        <h1 className="text-3xl font-bold text-gray-900 ml-5 mt-5">
                            <FontAwesomeIcon
                                className="mr-1"
                                icon={faChartLine}
                            />{" "}
                            Gráficos
                        </h1>
                        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                            <div className="w-full md:w-1/2">
                                <Select
                                    onChange={(e) =>
                                        editarPacienteSelecionado(e)
                                    }
                                    maxMenuHeight={150}
                                    components={{ NoOptionsMessage }}
                                    placeholder="Selecione o paciente"
                                    value={pacienteSelecionado}
                                    options={pacientes}
                                    onInputChange={(e) => {
                                        if (e && e.length >= 3) {
                                            filtrarPaciente(e);
                                        } else if (
                                            pacienteSelecionado === null
                                        ) {
                                            setPacientes([]);
                                        }
                                    }}
                                />
                            </div>
                            <div>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setQuantidadeDeColunas(2);
                                    }}
                                    className="p-2 text-base font-medium text-center text-gray-800 bg-gray-300 rounded-s-lg hover:bg-gray-400 focus:outline-none dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                    <FontAwesomeIcon icon={faTableCellsLarge} />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setQuantidadeDeColunas(1);
                                    }}
                                    className="p-2 text-base font-medium text-center text-gray-800 bg-gray-300 rounded-e-lg hover:bg-gray-400 focus:outline-none dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                    <FontAwesomeIcon icon={faList} />
                                </button>
                            </div>
                        </div>

                        <div
                            className={`p-3 grid grid-cols-${quantidadeDeColunas} md:grid-cols-${quantidadeDeColunas} gap-4`}
                        >
                            <div className="p-3 border border-gray-300 rounded-lg shadow-lg">
                                <AlturaPorIdade
                                    defaultValue={
                                        pacienteSelecionado?.sexo ===
                                        "MASCULINO"
                                            ? ALturaPorIdadeMenino
                                            : ALturaPorIdadeMenina
                                    }
                                    valorPacienteSelecionado={
                                        consultaDoPacienteSelecionado?.altura ||
                                        null
                                    }
                                    nomeDoPaciente={
                                        pacienteSelecionado?.nome.split(
                                            " ",
                                        )[0] || "---"
                                    }
                                    alturaAtual={altura}
                                    idadeEmMeses={
                                        pacienteSelecionado?.idadeEmMeses
                                    }
                                />
                            </div>
                            <div className="p-3 border border-gray-300 rounded-lg shadow-lg">
                                <PesoPorIdade
                                    defaultValue={
                                        pacienteSelecionado?.sexo ===
                                        "MASCULINO"
                                            ? PesoPorIdadeMenino
                                            : PesoPorIdadeMenina
                                    }
                                    valorPacienteSelecionado={
                                        consultaDoPacienteSelecionado?.peso ||
                                        null
                                    }
                                    nomeDoPaciente={
                                        pacienteSelecionado?.nome.split(
                                            " ",
                                        )[0] || "---"
                                    }
                                    pesoAtual={peso}
                                    idadeEmMeses={
                                        pacienteSelecionado?.idadeEmMeses
                                    }
                                />
                            </div>
                            <div className="p-3 border border-gray-300 rounded-lg shadow-lg">
                                <PerimetroCefalicoPorIdade
                                    defaultValue={
                                        pacienteSelecionado?.sexo ===
                                        "MASCULINO"
                                            ? PerimetroCefalicoMenino
                                            : PerimetroCefalicoMenina
                                    }
                                    valorPacienteSelecionado={
                                        consultaDoPacienteSelecionado?.perimetroCefalico ||
                                        null
                                    }
                                    nomeDoPaciente={
                                        pacienteSelecionado?.nome.split(
                                            " ",
                                        )[0] || "---"
                                    }
                                    perimetroCefalicoAtual={perimetroCefalico}
                                    idadeEmMeses={
                                        pacienteSelecionado?.idadeEmMeses
                                    }
                                />
                            </div>
                        </div>
                        <ConfiguracaoPacienteModal
                            authResponseDTO={authResponseDTO}
                            token={token}
                            onModalClose={onModalClose}
                            modalOpen={modalOpen}
                            titulo={"Editando paciente"}
                            isEditar={true}
                            paciente={pacienteSelecionado}
                            exibeHistorico={false}
                        />
                        {!consultaDoPacienteSelecionado &&
                            pacienteSelecionado && (
                                <div
                                    id="alert-border-2"
                                    className="fixed right-5 bottom-5 flex items-center p-4 mb-4 rounded-lg text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400 dark:bg-gray-800 dark:border-red-800"
                                    role="alert"
                                >
                                    <svg
                                        className="flex-shrink-0 w-4 h-4"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                    </svg>
                                    <div className="ms-3 text-sm font-medium">
                                        Paciente não possue dados para serem
                                        exibidos!
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            {/* </section> */}
        </>
    );
};

export default Graficos;
