import React, { useEffect, useState } from "react";
import { updateFuncionario } from "./FuncionarioService";
import ConfirmacaoModal from "./ConfirmacaoModal";

const PerfilFuncionarioModal = ({
    isModalOpen,
    onCloseModal,
    authResponseDTO,
    token,
}) => {
    const diasDaSemana = [
        { codigo: 0, nome: "Domingo", valor: "DOMINGO" },
        { codigo: 1, nome: "Segunda-feira", valor: "SEGUNDA_FEIRA" },
        { codigo: 2, nome: "Terça-feira", valor: "TERCA_FEIRA" },
        { codigo: 3, nome: "Quarta-feira", valor: "QUARTA_FEIRA" },
        { codigo: 4, nome: "Quinta-feira", valor: "QUINTA_FEIRA" },
        { codigo: 5, nome: "Sexta-feira", valor: "SEXTA_FEIRA" },
        { codigo: 6, nome: "Sábado", valor: "SABADO" },
    ];

    const [diasSelecionados, setDiasSelecionados] = useState(
        authResponseDTO.diasDeAtendimento,
    );
    const [exibeDropdownDiasDaSemana, setExibeDropdownDiasDaSemana] =
        useState(false);
    const [dadosDoUsuario, setDadosDoUsuario] = useState({
        username: authResponseDTO.username,
        cpfCnpj: authResponseDTO.cpfCnpj,
        telefone: authResponseDTO.telefone,
        email: authResponseDTO.email,
        dataDeNascimento: authResponseDTO.dataDeNascimento,
        documentoDeIdentificacao: authResponseDTO.documentoDeIdentificacao,
        funcao: authResponseDTO.funcao,
        especialidade: authResponseDTO.especialidade,
        valorDaConsulta: authResponseDTO.valorDaConsulta,
        atendePlano: authResponseDTO.atendePlano,
        atendeParticular: authResponseDTO.atendeParticular,
    });
    const salvarDadosDoUsuario = (event) => {
        event.preventDefault();
        
        if (window.confirm("Para salvar as alterações, faça login novamente agora. Caso não faça login novamente, os dados informados serão perdidos.")) {
            updateFuncionario(dadosDoUsuario, token, authResponseDTO);
            debugger
            window.location.href = window.location.origin + "/login";
            // window.location.reload();
        } else {
            window.location.reload();
            onCloseModal();
        }
    };

    const handleChange = (e) => {
        let { name, value } = e.target;

        if (name.includes("telefone")) {
            value = formatar(
                value,
                /(\d{2})(\d{1})(\d{4})(\d{4})/,
                "($1) $2 $3-$4",
            );
        } else if (name.includes("cpf")) {
            value = formatar(
                value,
                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                "$1.$2.$3-$4",
            );
        } else if (name.includes("atende")) {
            value = e.target.checked;
        }

        setDadosDoUsuario({ ...dadosDoUsuario, [name]: value });
    };

    function formatar(value, searchValue, replaceValue) {
        const valorInput = value.replace(/\D/g, "");
        const formatado = valorInput
            .replace()
            .replace(searchValue, replaceValue);

        return formatado;
    }

    return (
        <>
            {isModalOpen && (
                <div
                    id="top-right-modal"
                    data-modal-placement="top-right"
                    tabIndex="-1"
                    className="bg-black bg-opacity-50 fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] min-h-full justify-center items-start flex"
                >
                    <div className="relative w-full max-w-md max-h-full">
                        {/* <!-- Modal content --> */}
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            {/* <!-- Modal header --> */}
                            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    Editando perfil
                                </h3>
                                <button
                                    onClick={onCloseModal}
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    data-modal-hide="default-modal"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            {/* <!-- Modal body --> */}
                            <div className="p-6 space-y-6">
                                <form
                                    onSubmit={salvarDadosDoUsuario}
                                    id="formDadosPerfilFuncionario"
                                >
                                    <div className="relative z-0 w-full mb-6 group">
                                        <input
                                            disabled
                                            value={authResponseDTO.username}
                                            name="username"
                                            type="text"
                                            id="nome_completo_usuario"
                                            className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-not-allowed"
                                            placeholder=" "
                                        />
                                        <label
                                            for="nome_completo_usuario"
                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 cursor-not-allowed peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                                        >
                                            Nome completo
                                        </label>
                                    </div>
                                    <div className="grid md:grid-cols-2 md:gap-6">
                                        <div className="relative z-0 w-full group mb-6">
                                            <input
                                                value={dadosDoUsuario.cpfCnpj}
                                                onChange={handleChange}
                                                name="cpfCnpj"
                                                type="text"
                                                id="cpf_usuario"
                                                className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                placeholder=" "
                                                maxLength={14}
                                                required
                                            />
                                            <label
                                                for="cpf_usuario"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                                            >
                                                CPF
                                            </label>
                                        </div>
                                        <div className="relative z-0 w-full group mb-6">
                                            <input
                                                value={
                                                    authResponseDTO.documentoDeIdentificacao
                                                }
                                                disabled
                                                type="text"
                                                name="documentoDeIdentificacao"
                                                id="documento_profissional"
                                                className="cursor-not-allowed block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                placeholder=" "
                                                required
                                            />
                                            <label
                                                for="documento_profissional"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 cursor-not-allowed transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                                            >
                                                Documento profissional
                                            </label>
                                        </div>
                                    </div>
                                    <div className="relative z-0 w-full mb-6 group">
                                        <input
                                            value={dadosDoUsuario.email}
                                            onChange={handleChange}
                                            name="email"
                                            type="email"
                                            id="email_usuario"
                                            className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            placeholder=" "
                                            required
                                        />
                                        <label
                                            for="email_usuario"
                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                                        >
                                            E-mail
                                        </label>
                                    </div>
                                    <div className="grid md:grid-cols-2 md:gap-6">
                                        <div className="relative z-0 w-full group mb-6">
                                            <input
                                                value={
                                                    dadosDoUsuario.especialidade
                                                }
                                                onChange={handleChange}
                                                name="especialidade"
                                                type="text"
                                                id="especialidade_usuario"
                                                className={`block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
                                                    authResponseDTO.funcao ===
                                                    "RECEPCIONISTA"
                                                        ? "cursor-not-allowed"
                                                        : ""
                                                }`}
                                                placeholder=" "
                                                disabled={
                                                    authResponseDTO.funcao ===
                                                    "RECEPCIONISTA"
                                                }
                                            />
                                            <label
                                                for="especialidade_usuario"
                                                className={`absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1 ${
                                                    authResponseDTO.funcao ===
                                                    "RECEPCIONISTA"
                                                        ? "cursor-not-allowed"
                                                        : ""
                                                }`}
                                            >
                                                Especiaidade
                                            </label>
                                        </div>
                                        <div className="relative z-0 w-full group mb-6">
                                            <input
                                                value={authResponseDTO.funcao}
                                                disabled
                                                type="text"
                                                name="funcao"
                                                id="funcao_profissional"
                                                className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-not-allowed"
                                                placeholder=" "
                                                required
                                            />
                                            <label
                                                for="funcao_profissional"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1 cursor-not-allowed"
                                            >
                                                Função
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mb-6 grid md:grid-cols-2 md:gap-6">
                                        <div className="relative z-0 w-full group mb-6">
                                            <input
                                                value={dadosDoUsuario.telefone}
                                                onChange={handleChange}
                                                name="telefone"
                                                type="tel"
                                                id="telefone_usuario"
                                                className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                placeholder=" "
                                                required
                                            />
                                            <label
                                                for="telefone_usuario"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                                            >
                                                Telefone
                                            </label>
                                        </div>
                                        <div
                                            className={`relative mb-6 z-0 w-full group ${
                                                authResponseDTO.funcao ===
                                                "RECEPCIONISTA"
                                                    ? "hidden"
                                                    : ""
                                            }`}
                                        >
                                            <input
                                                value={
                                                    dadosDoUsuario.valorDaConsulta
                                                }
                                                onChange={handleChange}
                                                name="valorDaConsulta"
                                                type="number"
                                                id="valor_consulta_usuario"
                                                className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                placeholder=" "
                                                required={
                                                    authResponseDTO.funcao !==
                                                    "RECEPCIONISTA"
                                                }
                                            />
                                            <label
                                                for="valor_consulta_usuario"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                                            >
                                                Valor da consulta
                                            </label>
                                        </div>
                                    </div>
                                    <div
                                        className={`grid mb-6 md:grid-cols-2 md:gap-6 ${
                                            authResponseDTO.funcao ===
                                            "RECEPCIONISTA"
                                                ? "hidden"
                                                : ""
                                        }`}
                                    >
                                        <div className="relative z-0 mb-6 w-full group">
                                            <label className="relative inline-flex items-center cursor-pointer">
                                                <input
                                                    checked={
                                                        dadosDoUsuario.atendePlano
                                                    }
                                                    onChange={handleChange}
                                                    name="atendePlano"
                                                    type="checkbox"
                                                    className="sr-only peer"
                                                />
                                                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                    Atende plano?
                                                </span>
                                            </label>
                                        </div>
                                        <div className="relative z-0 mb-6 w-full group">
                                            <label className="relative inline-flex items-center cursor-pointer">
                                                <input
                                                    checked={
                                                        dadosDoUsuario.atendeParticular
                                                    }
                                                    onChange={handleChange}
                                                    name="atendeParticular"
                                                    type="checkbox"
                                                    className="sr-only peer"
                                                />
                                                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                    Atende particular?
                                                </span>
                                            </label>
                                        </div>
                                    </div>

                                    {/* <button onClick={() => setExibeDropdownDiasDaSemana(!exibeDropdownDiasDaSemana)} id="dropdownDefaultButton" data-dropdown-toggle="dropdown-teste" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Dias de atendimento <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                </svg>
                                </button> */}

                                    {/* <!-- Dropdown menu --> */}
                                    {/* <div id="dropdown-teste" className={`z-0 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 ${exibeDropdownDiasDaSemana ? '' : 'hidden'}`}>
                                    <ul className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSearchButton">
                                        {diasDaSemana.map((dia, index) => (
                                            <li>
                                                <div className="flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                    <input checked={dadosDoUsuario.diasDeAtendimento.includes(dia.codigo)} id={`checkbox-item- ${index}`} type="checkbox" value={dia.codigo} onChange={() => handleCheckboxChange(dia.codigo)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                    <label for={`checkbox-item- ${index}`} className="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{dia.nome}</label>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div> */}
                                </form>
                            </div>
                            {/* <!-- Modal footer --> */}
                            <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                <button
                                    onClick={onCloseModal}
                                    type="button"
                                    className="text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                >
                                    Fechar
                                </button>
                                <button
                                    type="submit"
                                    form="formDadosPerfilFuncionario"
                                    className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                >
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* {isModalConfirmacaoOpen && (
                <ConfirmacaoModal
                    isModalOpen={isModalConfirmacaoOpen}
                    onCloseModal={() => setIsModalConfirmacaoOpen(false)}
                    mensagem={
                        "Para salvar as alterações, faça login novamente agora. Caso não faça login novamente, os dados informados serão perdidos."
                    }
                    confirmButton={
                        <button
                            type="submit"
                            form="formDadosPerfilFuncionario"
                            className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                            Sim
                        </button>
                    }
                />
            )} */}
        </>
    );
};

export default PerfilFuncionarioModal;
