import { Month60, Month0 } from "../Months/MonthsRange";

export const MonthStart = Month0;
export const MonthEnd = Month60;

export const threePercent = [
	31.7, 34.3, 36, 37.2, 38.2, 39, 39.7, 40.4, 40.9, 41.3, 41.7, 42, 42.3, 42.6, 42.9, 43.1, 43.3, 43.5, 43.6, 43.8,
	44, 44.1, 44.3, 44.4, 44.6, 44.7, 44.8, 44.9, 45.1, 45.2, 45.3, 45.4, 45.5, 45.6, 45.7, 45.8, 45.9, 45.9, 46, 46.1,
	46.2, 46.2, 46.3, 46.4, 46.4, 46.5, 46.5, 46.6, 46.7, 46.7, 46.8, 46.8, 46.9, 46.9, 47, 47, 47.1, 47.1, 47.2, 47.2,
	47.2
];

export const fifteenPercent = [
	32.7, 35.3, 37, 38.2, 39.3, 40.1, 40.8, 41.5, 42, 42.4, 42.8, 43.2, 43.5, 43.8, 44, 44.2, 44.4, 44.6, 44.8, 45,
	45.1, 45.3, 45.4, 45.6, 45.7, 45.9, 46, 46.1, 46.3, 46.4, 46.5, 46.6, 46.7, 46.8, 46.9, 47, 47, 47.1, 47.2, 47.3,
	47.4, 47.4, 47.5, 47.6, 47.6, 47.7, 47.7, 47.8, 47.9, 47.9, 48, 48, 48.1, 48.1, 48.2, 48.2, 48.3, 48.3, 48.4, 48.4,
	48.4
];

export const fiftyPercent = [
	33.9, 36.5, 38.3, 39.5, 40.6, 41.5, 42.2, 42.8, 43.4, 43.8, 44.2, 44.6, 44.9, 45.2, 45.4, 45.7, 45.9, 46.1, 46.2,
	46.4, 46.6, 46.7, 46.9, 47, 47.2, 47.3, 47.5, 47.6, 47.7, 47.8, 47.9, 48, 48.1, 48.2, 48.3, 48.4, 48.5, 48.6, 48.7,
	48.7, 48.8, 48.9, 49, 49, 49.1, 49.2, 49.2, 49.3, 49.3, 49.4, 49.4, 49.5, 49.5, 49.6, 49.6, 49.7, 49.7, 49.8, 49.8,
	49.9, 49.9
];

export const eightyFivePercent = [
	35.1, 37.8, 39.5, 40.8, 41.9, 42.8, 43.5, 44.2, 44.7, 45.2, 45.6, 46, 46.3, 46.6, 46.8, 47.1, 47.3, 47.5, 47.7,
	47.8, 48, 48.2, 48.3, 48.5, 48.6, 48.8, 48.9, 49, 49.2, 49.3, 49.4, 49.5, 49.6, 49.7, 49.8, 49.9, 50, 50.1, 50.1,
	50.2, 50.3, 50.4, 50.4, 50.5, 50.6, 50.6, 50.7, 50.7, 50.8, 50.9, 50.9, 51, 51, 51.1, 51.1, 51.2, 51.2, 51.3, 51.3,
	51.4, 51.4  
];

export const ninetySevenPercent = [
	36.1, 38.8, 40.5, 41.9, 43, 43.9, 44.6, 45.3, 45.9, 46.3, 46.8, 47.1, 47.5, 47.7, 48, 48.2, 48.5, 48.7, 48.8, 49,
	49.2, 49.4, 49.5, 49.7, 49.8, 49.9, 50.1, 50.2, 50.3, 50.5, 50.6, 50.7, 50.8, 50.9, 51, 51.1, 51.2, 51.3, 51.3,
	51.4, 51.5, 51.6, 51.6, 51.7, 51.8, 51.8, 51.9, 51.9, 52, 52.1, 52.1, 52.2, 52.2, 52.3, 52.3, 52.4, 52.4, 52.5,
	52.5, 52.6, 52.6
];
