import React, { useState, useEffect } from "react";
import ConfiguracaoPacienteModal from "./ConfiguracaoPacienteModal";
import {
    deletePacientesById,
    getPacientes,
    getPacientesByNome,
} from "./PacientesService";
import { getConsultasPorIdPaciente } from "./ConsultaService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsersLine } from "@fortawesome/free-solid-svg-icons";

const OpcoesPacientes = ({ authResponseDTO, token }) => {
    const [tituloModal, setTituloModal] = useState("Novo cadastro");
    const [ehUmModalEditavel, setEhUmModalEditavel] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [pacienteModal, setPacienteModal] = useState(null);
    const [pacientes, setPacientes] = useState([]);
    const [isButtonStatic, setIsButtonStatic] = useState(true);
    const [consultasPorIdPaciente, setConsultasPorIdPaciente] = useState([]);
    const [nomeDoPacienteParaConsultar, setNomeDoPacienteParaConsultar] =
        useState("");
    //TODO
    // Só pode exibir o histórico de quem não é psicólogo
    const [isProfissionalDeSaude, setIsProfissionalDeSaude] = useState(false);

    const openModal = ({ titulo, isEditavel, paciente, modalOpen }) => {
        setModalOpen(modalOpen);
        setTituloModal(titulo);
        setEhUmModalEditavel(isEditavel);
        setPacienteModal(paciente);
    };

    useEffect(() => {
        getPacientes(token)
            .then((response) => {
                setPacientes(response);
            })
            .catch((error) => {
                console.error("Erro ao acessar a API:", error);
            });
    }, []);

    const onclickAdiacionar = () => {
        setModalOpen(true);
        setTituloModal("Novo cadastro");
        setEhUmModalEditavel(false);
        setPacienteModal(null);
    };

    const onclickNomeDoPaciente = ({ titulo, paciente, isEditavel }) => {
        setConsultasPorIdPaciente([]);
        setIsButtonStatic(true);
        setModalOpen(true);
        setTituloModal(titulo);
        setEhUmModalEditavel(isEditavel);
        setPacienteModal(paciente);
    };

    const onModalClose = () => {
        setModalOpen(false);
    };

    const [isVisible, setIsVisible] = useState(false);

    const handleSubmitFiltrarPacientePorNome = (e) => {
        e.preventDefault();

        getPacientesByNome(token, nomeDoPacienteParaConsultar)
            .then((response) => {
                if (response.length > 0) {
                    setPacientes(response);
                } else {
                    alert("Nenhum resultado encontrado.");
                }
            })
            .catch((error) => {
                console.error("Erro ao acessar a API:", error);
            });
    };

    const removerPaciente = (paciente) => {
        if (
            window.confirm(
                `Ao clicar em 'Ok' o cadastro do(a) paciente ${paciente.nome} será removido.\n\nTem certeza que deseja continuar?\nEsta ação não poderá ser desfeita!`,
            )
        ) {
            setModalOpen(false);
            deletePacientesById(token, paciente.id);
        }
    };

    return (
        <>
            <section className="w-full h-full bg-gray-900 dark:bg-gray-900 p-3 sm:p-5">
                <div className="mx-auto px-4 lg:px-12">
                    <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                        <h3 className="text-3xl font-bold text-gray-900 ml-5 mt-5">
                            <FontAwesomeIcon
                                className="text-2xl mr-1"
                                icon={faUsersLine}
                            />{" "}
                            Pacientes
                        </h3>
                        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                            <div className="w-full md:w-1/2">
                                <form
                                    onSubmit={
                                        handleSubmitFiltrarPacientePorNome
                                    }
                                    className="flex items-center"
                                >
                                    <label
                                        for="simple-search"
                                        className="sr-only"
                                    >
                                        Pesquisar
                                    </label>
                                    <div className="relative w-full">
                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <svg
                                                aria-hidden="true"
                                                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                                fill="currentColor"
                                                viewbox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                    clip-rule="evenodd"
                                                />
                                            </svg>
                                        </div>
                                        <input
                                            onChange={(e) =>
                                                setNomeDoPacienteParaConsultar(
                                                    e.target.value,
                                                )
                                            }
                                            value={nomeDoPacienteParaConsultar}
                                            type="text"
                                            id="simple-search"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-600 focus:border-teal-600 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                            placeholder="Pesquisar pelo nome"
                                            required=""
                                        />
                                        {/* <button
                                            type="submit"
                                            className="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white rounded-r-lg border bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800"
                                        >
                                            <svg
                                                className="w-4 h-4"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                                />
                                            </svg>
                                            <span className="sr-only">Search</span>
                                        </button> */}
                                    </div>
                                </form>
                            </div>
                            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                                <button
                                    onClick={onclickAdiacionar}
                                    type="button"
                                    // className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-4 py-2 text-center"
                                    className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-4 py-2 text-center"
                                >
                                    + Adicionar
                                </button>
                            </div>
                        </div>
                        <div className="overflow-auto altura-tabela">
                            <table className="w-full overflow-auto text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-xs uppercase text-white bg-teal-600 border-b">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-4 py-3"
                                            title="Nome do paciente"
                                        >
                                            Nome
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-4 py-3"
                                            title="Idade do paciente"
                                        >
                                            Idade
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-4 py-3"
                                            title="Idade do paciente"
                                        >
                                            Responsável legal
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-4 py-3"
                                            title="Telefone do paciente"
                                        >
                                            Telefone
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-4 py-3"
                                            title="E-mail do paciente"
                                        >
                                            E-mail
                                        </th>
                                        <th
                                            scope="col"
                                            className={`px-4 py-3 ${
                                                authResponseDTO.funcao !==
                                                "RECEPCIONISTA"
                                                    ? "hidden"
                                                    : ""
                                            }`}
                                            title="Ações do usuário"
                                        >
                                            Ações
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="border-b">
                                    {pacientes.map((paciente, index) => (
                                        <tr
                                            className="border-b hover:bg-gray-200 cursor-pointer"
                                            onClick={() =>
                                                onclickNomeDoPaciente({
                                                    titulo: "Informações do paciente",
                                                    paciente: paciente,
                                                    isEditavel: true,
                                                })
                                            }
                                            key={index}
                                        >
                                            <td
                                                key={index}
                                                scope="row"
                                                className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                                {paciente.nome}
                                            </td>
                                            <td className="px-4 py-3">
                                                {paciente.idade}
                                            </td>
                                            <td className="px-4 py-3">
                                                {paciente.responsaveis[1]
                                                    ? paciente.responsaveis[1]
                                                          .nomeAbreviado
                                                    : paciente.responsaveis[0]
                                                          .nomeAbreviado}
                                            </td>
                                            <td className="px-4 py-3">
                                                {paciente.responsaveis[1]
                                                    ? paciente.responsaveis[1]
                                                          .telefone
                                                    : paciente.responsaveis[0]
                                                          .telefone}
                                            </td>
                                            <td className="px-4 py-3">
                                                {paciente.responsaveis[1]
                                                    ? paciente.responsaveis[1]
                                                          .email
                                                    : paciente.responsaveis[0]
                                                          .email}
                                            </td>
                                            <td
                                                className={`px-4 py-3 ${
                                                    authResponseDTO.funcao !==
                                                    "RECEPCIONISTA"
                                                        ? "hidden"
                                                        : ""
                                                }`}
                                            >
                                                <button
                                                    type="button"
                                                    title="Remover"
                                                    onClick={() =>
                                                        removerPaciente(
                                                            paciente,
                                                        )
                                                    }
                                                    className="bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 text-white rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height="15"
                                                        width="15"
                                                        viewBox="0 0 448 512"
                                                    >
                                                        {/* <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--> */}
                                                        <path
                                                            fill="#ffffff"
                                                            d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"
                                                        />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <nav
                            className="hidden flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
                            aria-label="Table navigation"
                        >
                            <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                Exibindo
                                <span className="font-semibold text-gray-900 dark:text-white">
                                    {" "}
                                    1-10{" "}
                                </span>
                                de
                                <span className="font-semibold text-gray-900 dark:text-white">
                                    {" "}
                                    {pacientes.length}{" "}
                                </span>
                            </span>
                            <ul className="inline-flex items-stretch -space-x-px">
                                <li>
                                    <a
                                        href="#"
                                        className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    >
                                        <span className="sr-only">
                                            Anterior
                                        </span>
                                        <svg
                                            className="w-5 h-5"
                                            aria-hidden="true"
                                            fill="currentColor"
                                            viewbox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        className="flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    >
                                        1
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        className="flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    >
                                        2
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        aria-current="page"
                                        className="flex items-center justify-center text-sm z-10 py-2 px-3 leading-tight text-primary-600 bg-primary-50 border border-primary-300 hover:bg-primary-100 hover:text-primary-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                                    >
                                        3
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        className="flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    >
                                        ...
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        className="flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    >
                                        100
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    >
                                        <span className="sr-only">Próxima</span>
                                        <svg
                                            className="w-5 h-5"
                                            aria-hidden="true"
                                            fill="currentColor"
                                            viewbox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
            <ConfiguracaoPacienteModal
                authResponseDTO={authResponseDTO}
                consultasPorIdPaciente={consultasPorIdPaciente}
                setConsultasPorIdPaciente={setConsultasPorIdPaciente}
                isButtonStatic={isButtonStatic}
                setIsButtonStatic={setIsButtonStatic}
                token={token}
                onModalClose={onModalClose}
                modalOpen={modalOpen}
                titulo={tituloModal}
                // subTituloModal={subTituloModal}
                isEditar={ehUmModalEditavel}
                paciente={pacienteModal}
                exibeHistorico={true}
            />
            {/* <ConfirmacaoModal isModalOpen={true} mensagem={'Você tem certeza que deseja deletar este usuário?'} /> */}
        </>
    );
};

export default OpcoesPacientes;
