import React, { useState } from "react";
import HistoricoCompletoModal from "./HistoricoCompletoModal";

const TimeLinePaciente = ({
    consultasPorIdPaciente,
    copiarDadosParaConsultaAtual,
    authResponseDTO,
    isCopiarDados
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [consultaAtual, setConsultaAtual] = useState({});

    if (consultasPorIdPaciente && consultasPorIdPaciente.length <= 0) {
        return (
            <div className="text-center relative border-l border-gray-300 dark:border-gray-700">
                Não há históricos para exibir.
            </div>
        );
    }

    return (
        <>
            <ol className="relative border-l border-gray-300 dark:border-gray-700">
                {consultasPorIdPaciente?.map((consulta, index) =>
                    authResponseDTO.funcao !== "RECEPCIONISTA" ? (
                        <li className="mb-10 ml-4">
                            <div className="absolute w-3 h-3 bg-blue-500 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                            <time className="mb-5 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                                {consulta.dataConsultaFormatada} -{" "}
                                {consulta.usuario
                                    ? consulta.usuario.label
                                    : "---"}
                            </time>
                            <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                                <strong>Anamnese:</strong>{" "}
                                {consulta.anamnese.queixaPrincipal}
                            </p>
                            <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                                <strong>Conduta:</strong>{" "}
                                {consulta.conduta.conduta}
                            </p>
                            <button
                                onClick={() => {
                                    setIsModalOpen(true);
                                    setConsultaAtual(
                                        consultasPorIdPaciente[index],
                                    );
                                }}
                                type="button"
                                className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Ver mais
                            </button>
                            <HistoricoCompletoModal
                                copiarDadosParaConsultaAtual={
                                    copiarDadosParaConsultaAtual
                                }
                                isModalOpen={isModalOpen}
                                onCloseModal={() => setIsModalOpen(false)}
                                consulta={consultaAtual}
                                isCopiarDados={isCopiarDados}
                            />
                        </li>
                    ) : (
                        <li className="mb-4 ml-4">
                            <div className="absolute w-3 h-3 bg-blue-500 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                            <time className="mb-5 text-sm font-semibold leading-none text-gray-600 dark:text-gray-500">
                                {consulta.dataConsultaFormatada} -{" "}
                                {consulta.usuario
                                    ? consulta.usuario.label
                                    : "---"}
                            </time>
                        </li>
                    )
                )}
            </ol>
        </>
    );
};

export default TimeLinePaciente;
