export const getFuncionarios = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_URL_API}/api/usuario`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
    });

    return await response.json();
};

const getFuncionarioById = async (token, id) => {
    const response = await fetch(`${process.env.REACT_APP_URL_API}/api/usuario/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
    });

    return await response.json();
};

export const updateFuncionario = async (dadosDoUsuario, token, authResponseDTO) => {
    await fetch(`${process.env.REACT_APP_URL_API}/api/usuario/${authResponseDTO.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(dadosDoUsuario)
    }).then(async response => {
        const resp = await response;

        if (resp.ok) {
            localStorage.setItem('usuario', JSON.stringify(await getFuncionarioById(token, authResponseDTO.id)))
            alert("Dados salvos com sucesso.");
            // window.location.reload();
        } else {
            alert("Erro ao salvar dados.");
        }
    }).catch(error => {
        console.error("Erro na requisição:", error);
    });
};