import React from "react";

const HistoricoCompletoModal = ({
    consulta,
    isModalOpen,
    onCloseModal,
    copiarDadosParaConsultaAtual,
    isCopiarDados,
}) => {
    const copiarDados = () => {
        copiarDadosParaConsultaAtual(consulta);
        onCloseModal();
    };

    return (
        <>
            {isModalOpen && (
                <div
                    id="top-right-modal"
                    data-modal-placement="top-right"
                    tabIndex="-1"
                    className="bg-black bg-opacity-50 fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] min-h-full justify-center items-start flex"
                >
                    <div className="relative w-full max-w-md max-h-full">
                        {/* <!-- Modal content --> */}
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            {/* <!-- Modal header --> */}
                            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    Histórico completo
                                </h3>
                                <button
                                    onClick={onCloseModal}
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    data-modal-hide="novo-evento-modal"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <div className="p-6 space-y-6">
                                {/* {consulta} */}
                                <ol className="space-y-4 text-gray-500 list-decimal list-inside dark:text-gray-400">
                                    <li>
                                        Anamnese
                                        <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                                            <li>
                                                <strong>
                                                    Queixa principal:{" "}
                                                </strong>
                                                {consulta.anamnese
                                                    .queixaPrincipal || "---"}
                                            </li>
                                            <li>
                                                <strong>
                                                    História da Moléstia Atual
                                                    (HMA):{" "}
                                                </strong>
                                                {consulta.anamnese
                                                    .historiaDaMolestiaAtual ||
                                                    "---"}
                                            </li>
                                            <li>
                                                <strong>
                                                    Revisão de Sistemas:{" "}
                                                </strong>
                                                {consulta.anamnese
                                                    .revisaoDeSistemas || "---"}
                                            </li>
                                            <li>
                                                <strong>
                                                    História Patológica
                                                    Pregressa:{" "}
                                                </strong>
                                                {consulta.anamnese
                                                    .historiaPatologicaPregressa ||
                                                    "---"}
                                            </li>
                                            <li>
                                                <strong>
                                                    História Familiar:{" "}
                                                </strong>
                                                {consulta.anamnese
                                                    .historiaFamiliar || "---"}
                                            </li>
                                            <li>
                                                <strong>
                                                    História Social:{" "}
                                                </strong>
                                                {consulta.anamnese
                                                    .historiaSocial || "---"}
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Exame físico
                                        <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                                            <li>
                                                <strong>Descrição: </strong>
                                                {consulta.exameFisico
                                                    .exameFisico || "---"}
                                            </li>
                                            <li>
                                                <strong>Altura: </strong>
                                                {consulta.exameFisico.altura
                                                    ? consulta.exameFisico
                                                          .altura + "cm"
                                                    : "---"}
                                            </li>
                                            <li>
                                                <strong>Peso: </strong>
                                                {consulta.exameFisico.peso
                                                    ? consulta.exameFisico
                                                          .peso + "kg"
                                                    : "---"}
                                            </li>
                                            <li>
                                                <strong>
                                                    Perímetro cefálico:{" "}
                                                </strong>
                                                {consulta.exameFisico
                                                    .perimetroCefalico
                                                    ? consulta.exameFisico
                                                          .perimetroCefalico +
                                                      "cm"
                                                    : "---"}
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Exames complementares
                                        <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                                            <li>
                                                <strong>Descrição: </strong>
                                                {consulta.exameComplementar
                                                    .exameComplementar || "---"}
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Impressão e Conduta
                                        <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                                            <li>
                                                <strong>Descrição: </strong>
                                                {consulta.conduta.conduta ||
                                                    "---"}
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                            <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                <button
                                    onClick={onCloseModal}
                                    type="button"
                                    className="text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                    data-modal-hide="novo-evento-modal"
                                >
                                    Fechar
                                </button>
                                <button
                                    onClick={copiarDados}
                                    type="button"
                                    className={`text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 ${!isCopiarDados ? 'hidden' : ''}`}
                                >
                                    Copiar dados
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default HistoricoCompletoModal;
