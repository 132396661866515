export const getTodosPacientes = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_URL_API}/api/pacientes/todos`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
    });

    return await response.json();
};

export const getPacientes = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_URL_API}/api/pacientes`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
    });

    return await response.json();
};

export const getPacientesByNome = async (token, nome) => {
    const response = await fetch(`${process.env.REACT_APP_URL_API}/api/pacientes/nome/${nome}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
    });

    return await response.json();
};

export const postPaciente = async (paciente, token) => {
    fetch(`${process.env.REACT_APP_URL_API}/api/pacientes`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': token
        },
        body: JSON.stringify(paciente)
    })
        .then(response => {
            if (response.ok) {
                window.location.reload();
            } else {
                alert("Erro ao enviar dados.");
            }
        })
        .catch(error => {
            console.error("Erro na requisição:", error);
        });
}

export const putPaciente = async (paciente, token) => {
    fetch(`${process.env.REACT_APP_URL_API}/api/pacientes/${paciente.id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            'Authorization': token
        },
        body: JSON.stringify(paciente)
    })
        .then(response => {
            if (response.ok) {
                window.location.reload();
            } else {
                alert("Erro ao enviar dados.");
            }
        })
        .catch(error => {
            console.error("Erro na requisição:", error);
        });
}

export const deletePacientesById = async (token, idPaciente) => {
    await fetch(`${process.env.REACT_APP_URL_API}/api/pacientes/${idPaciente}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
    }).then(response => {
        if (response.ok) {
            alert("Paciente removido com sucesso!");
        } else if (response.status === 406) {
            alert("Paciente com consulta cadastrada! Cadastro não pode ser removido!");
        } else {
            alert("Erro ao remover paciente! Status do erro: ", response.status);
        }
        
        window.location.reload();
    })
        .catch(error => {
            alert("Erro na requisição: ", error);
        });
};