import React, { useEffect, useState } from "react";
import { postPaciente, putPaciente } from "./PacientesService";

const estados = [
    { uf: "AC", nome: "Acre" },
    { uf: "AL", nome: "Alagoas" },
    { uf: "AP", nome: "Amapá" },
    { uf: "AM", nome: "Amazonas" },
    { uf: "BA", nome: "Bahia" },
    { uf: "CE", nome: "Ceará" },
    { uf: "DF", nome: "Distrito Federal" },
    { uf: "ES", nome: "Espírito Santo" },
    { uf: "GO", nome: "Goiás" },
    { uf: "MA", nome: "Maranhão" },
    { uf: "MT", nome: "Mato Grosso" },
    { uf: "MS", nome: "Mato Grosso do Sul" },
    { uf: "MG", nome: "Minas Gerais" },
    { uf: "PA", nome: "Pará" },
    { uf: "PB", nome: "Paraíba" },
    { uf: "PR", nome: "Paraná" },
    { uf: "PE", nome: "Pernambuco" },
    { uf: "PI", nome: "Piauí" },
    { uf: "RJ", nome: "Rio de Janeiro" },
    { uf: "RN", nome: "Rio Grande do Norte" },
    { uf: "RS", nome: "Rio Grande do Sul" },
    { uf: "RO", nome: "Rondônia" },
    { uf: "RR", nome: "Roraima" },
    { uf: "SC", nome: "Santa Catarina" },
    { uf: "SP", nome: "São Paulo" },
    { uf: "SE", nome: "Sergipe" },
    { uf: "TO", nome: "Tocantins" },
];

const TipoSanguineo = {
    A_POSITIVO: "A+",
    A_NEGATIVO: "A-",
    B_POSITIVO: "B+",
    B_NEGATIVO: "B-",
    AB_POSITIVO: "AB+",
    AB_NEGATIVO: "AB-",
    O_POSITIVO: "O+",
    O_NEGATIVO: "O-",
};

const sexo = {
    MASCULINO: "M",
    FEMININO: "F",
};

const FormCriacaoPaciente = ({
    isEditar,
    informacoesPaciente,
    temInformacaoDosPacientes,
    token,
}) => {
    const descricaoResponsaveis = [" do pai", " da mãe"];
    const estadosOrdenados = estados
        .slice()
        .sort((a, b) => a.nome.localeCompare(b.nome));
    const [paciente, setPaciente] = useState({
        nome: "",
        tipoSanguineo: "NENHUM",
        dataDeNascimento: "",
        responsaveis: [
            {
                nome: "",
                telefone: "",
                email: "",
                dataDeNascimento: "",
            },
            {
                nome: "",
                telefone: "",
                email: "",
                dataDeNascimento: "",
            },
        ],
        cpfResponsavelFinanceiro: "",
        endereco: {
            cep: "",
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            cidade: "",
            uf: "",
        },
        sexo: "",
    });

    function formatar(value, searchValue, replaceValue) {
        const valorInput = value.replace(/\D/g, "");
        const formatado = valorInput
            .replace()
            .replace(searchValue, replaceValue);

        return formatado;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isEditar) {
            postPaciente(paciente, token);
        } else {
            putPaciente(paciente, token);
        }
    };

    const handleChangeFormData = (e) => {
        let { name, value } = e.target;

        if (name.includes("cpf")) {
            value = formatar(
                value,
                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                "$1.$2.$3-$4",
            );
        }

        setPaciente({ ...paciente, [name]: value });
    };

    const handleResponsavelChange = (e, index) => {
        const { name, value } = e.target;
        const updatedResponsaveis = [...paciente.responsaveis];

        if (name.includes("telefone")) {
            updatedResponsaveis[index][name] = formatar(
                value,
                /(\d{2})(\d{1})(\d{4})(\d{4})/,
                "($1) $2 $3-$4",
            );
        } else {
            updatedResponsaveis[index][name] = value;
        }

        setPaciente({ ...paciente, responsaveis: updatedResponsaveis });
    };

    const handleEnderecoChange = (e, index) => {
        const { name, value } = e.target;
        const enderecoField = name.split("_")[1];
        setPaciente({
            ...paciente,
            endereco: {
                ...paciente.endereco,
                [enderecoField]: value,
            },
        });
    };

    useEffect(() => {
        if (isEditar) {
            setPaciente(informacoesPaciente);
        }
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit} id="formModalPaciente">
                <h1 className="mb-3">Dados do paciente</h1>
                <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-6 group">
                        <input
                            onChange={handleChangeFormData}
                            value={paciente.nome}
                            type="text"
                            name="nome"
                            id="floating_nome_paciente"
                            className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                        />
                        {/* <input onChange={handleChangeFormData} value={temInformacaoDosPacientes ? informacoesPaciente.nome : paciente.nome} type="text" name="nome" id="floating_nome_paciente" className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required /> */}
                        <label
                            for="floating_nome_paciente"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                        >
                            Nome paciente
                        </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                        <select
                            value={paciente.sexo}
                            onChange={handleChangeFormData}
                            id="floating_sexo"
                            name="sexo"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                            <option value="NAO_INFORMADO">Sexo</option>
                            {Object.keys(sexo).map((chave, index) => (
                                <option key={index} value={chave}>
                                    {chave}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-6 group">
                        <select
                            value={paciente.tipoSanguineo}
                            onChange={handleChangeFormData}
                            id="floating_tipo_sanguineo"
                            name="tipoSanguineo"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                            <option value="NENHUM">Tipo sanguíneo</option>
                            {Object.keys(TipoSanguineo).map((chave, index) => (
                                <option key={index} value={chave}>
                                    {TipoSanguineo[chave]}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                        <input
                            onChange={handleChangeFormData}
                            value={paciente.dataDeNascimento}
                            type="date"
                            name="dataDeNascimento"
                            id="floating_data_nascimento"
                            className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                        />
                        <label
                            for="floating_data_nascimento"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                        >
                            Data de nascimento
                        </label>
                    </div>
                </div>

                <h1 className="mb-3">Dados do(s) responsável(eis)</h1>

                {paciente.responsaveis.map((responsavel, index) => (
                    <div key={index}>
                        <h2 className="mb-3 text-gray-500">{`Dados ${descricaoResponsaveis[index]}`}</h2>
                        <div className="grid md:grid-cols-2 md:gap-6">
                            <div className="relative z-0 w-full mb-6 group">
                                <input
                                    onChange={(e) =>
                                        handleResponsavelChange(e, index)
                                    }
                                    id={`responsavelNome_${index}`}
                                    name="nome"
                                    value={
                                        temInformacaoDosPacientes
                                            ? informacoesPaciente.responsaveis[
                                                  index
                                              ].nome
                                            : responsavel.nome
                                    }
                                    type="text"
                                    className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=" "
                                    required={index === 1}
                                />
                                <label
                                    for={`responsavelNome_${index}`}
                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                                >{`Nome ${descricaoResponsaveis[index]}`}</label>
                            </div>
                            <div className="relative z-0 w-full mb-6 group">
                                <input
                                    onChange={(e) =>
                                        handleResponsavelChange(e, index)
                                    }
                                    id={`responsavelTelefone_${index}`}
                                    name="telefone"
                                    value={
                                        temInformacaoDosPacientes
                                            ? informacoesPaciente.responsaveis[
                                                  index
                                              ].telefone
                                            : responsavel.telefone
                                    }
                                    type="tel"
                                    className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=" "
                                    maxLength={15}
                                />
                                <label
                                    for={`responsavelTelefone_${index}`}
                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                                >{`Celular ${descricaoResponsaveis[index]}`}</label>
                            </div>
                        </div>
                        <div className="grid md:grid-cols-2 md:gap-6">
                            <div className="relative z-0 w-full mb-6 group">
                                <input
                                    onChange={(e) =>
                                        handleResponsavelChange(e, index)
                                    }
                                    id={`responsavelEmail_${index}`}
                                    name="email"
                                    value={
                                        temInformacaoDosPacientes
                                            ? informacoesPaciente.responsaveis[
                                                  index
                                              ].email
                                            : responsavel.email
                                    }
                                    type="email"
                                    className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=" "
                                    required={index === 1}
                                />
                                <label
                                    for={`responsavelEmail_${index}`}
                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                                >{`E-mail ${descricaoResponsaveis[index]}`}</label>
                            </div>
                            <div className="relative z-0 w-full mb-6 group">
                                <input
                                    onChange={(e) =>
                                        handleResponsavelChange(e, index)
                                    }
                                    id={`responsaveDataDeNascimento_${index}`}
                                    name="dataDeNascimento"
                                    value={
                                        temInformacaoDosPacientes
                                            ? informacoesPaciente.responsaveis[
                                                  index
                                              ].dataDeNascimento
                                            : responsavel.dataDeNascimento
                                    }
                                    type="date"
                                    className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=" "
                                    required={index === 1}
                                />
                                <label
                                    for={`responsaveDataDeNascimento_${index}`}
                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                                >{`Data de nascimento ${descricaoResponsaveis[index]}`}</label>
                            </div>
                        </div>
                    </div>
                ))}
                <div className="relative z-0 w-full mb-6 group">
                    <input
                        onChange={handleChangeFormData}
                        value={paciente.cpfResponsavelFinanceiro}
                        name="cpfResponsavelFinanceiro"
                        type="text"
                        id="floating_cpf_responsavel_financeiro"
                        className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        maxLength={15}
                    />
                    <label
                        for="floating_cpf_responsavel_financeiro"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                    >
                        CPF responsável financeiro
                    </label>
                </div>

                <h6 className="mb-3">Dados do endereço</h6>

                <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-6 group">
                        <input
                            onChange={handleEnderecoChange}
                            value={paciente.endereco.cep}
                            type="text"
                            name="floating_cep"
                            id="floating_cep"
                            className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                        />
                        <label
                            for="floating_cep"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                        >
                            CEP
                        </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                        <input
                            onChange={handleEnderecoChange}
                            value={paciente.endereco.logradouro}
                            type="text"
                            name="floating_logradouro"
                            id="floating_logradouro"
                            className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                        />
                        <label
                            for="floating_logradouro"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                        >
                            Logradouro
                        </label>
                    </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-6 group">
                        <input
                            onChange={handleEnderecoChange}
                            value={paciente.endereco.numero}
                            type="text"
                            name="floating_numero"
                            id="floating_numero"
                            className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                        />
                        <label
                            for="floating_numero"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                        >
                            Número
                        </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                        <input
                            onChange={handleEnderecoChange}
                            value={paciente.endereco.complemento}
                            type="text"
                            name="floating_complemento"
                            id="floating_complemento"
                            className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                        />
                        <label
                            for="floating_complemento"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                        >
                            Complemento
                        </label>
                    </div>
                </div>
                <div className="grid md:grid-cols-3 md:gap-6">
                    <div className="relative z-0 w-full mb-6 group">
                        <input
                            onChange={handleEnderecoChange}
                            value={paciente.endereco.bairro}
                            type="text"
                            name="floating_bairro"
                            id="floating_bairro"
                            className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                        />
                        <label
                            for="floating_bairro"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                        >
                            Bairro
                        </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                        <input
                            onChange={handleEnderecoChange}
                            value={paciente.endereco.cidade}
                            type="text"
                            name="floating_cidade"
                            id="floating_cidade"
                            className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                        />
                        <label
                            for="floating_cidade"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                        >
                            Cidade
                        </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                        <select
                            onChange={handleEnderecoChange}
                            value={paciente.endereco.uf}
                            name="select_uf"
                            id="select_uf"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            required
                        >
                            <option value="">UF</option>
                            {estadosOrdenados.map((estado) => (
                                <option key={estado.uf} value={estado.uf}>
                                    {estado.nome} - {estado.uf}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </form>
        </>
    );
};

export default FormCriacaoPaciente;
