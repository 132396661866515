import React, { useEffect, useState } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCalendarDays, faUsers, faChartLine, faGear } from "@fortawesome/free-solid-svg-icons";
import PerfilFuncionarioModal from "./PerfilFuncionarioModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGear,
    faPowerOff,
    faRightFromBracket,
    faUser,
    faUserDoctor,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown, Collapse } from "flowbite";

const NavBar = ({ authResponseDTO, token }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [showDropDownUser, setShowDropDownUser] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [navbar, setNavbar] = useState(false);

    useEffect(() => {
        const $targetElDropdown = document.getElementById("user-dropdown");
        const $targetElNavbar = document.getElementById("navbar-user");
        const $triggerElNavbar = document.getElementById("navbar-user-button");

        // set the element that trigger the dropdown menu on click
        const $triggerElDropdown = document.getElementById("user-menu-button");

        // options with default values
        const options = {
            placement: "bottom",
            triggerType: "click",
            offsetSkidding: 0,
            offsetDistance: 10,
            delay: 300,
        };

        // instance options object
        const instanceOptionsDropdow = {
            id: "dropdownMenu",
            override: true,
        };

        const instanceOptionsNavbar = {
            id: "navbar-user",
            override: true,
        };

        setDropdown(
            new Dropdown(
                $targetElDropdown,
                $triggerElDropdown,
                options,
                instanceOptionsDropdow,
            ),
        );

        setNavbar(
            new Collapse(
                $targetElNavbar,
                $triggerElNavbar,
                options,
                instanceOptionsNavbar,
            ),
        );
    }, []);

    const zerarLocalStorage = () => {
        localStorage.clear();
        window.location.href = "/login";
    };

    return (
        <>
            {authResponseDTO && (
                <nav className="bg-gradient-to-l from-cyan-400 via-cyan-500 to-cyan-600 shadow-cyan-500/50 shadow-md py-3">
                    <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                        <a href="" className="flex items-center">
                            <span className="self-center text-2xl text-white font-semibold whitespace-nowrap dark:text-white">
                                M.R.T. Medical
                            </span>
                        </a>
                        <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                            <button
                                onClick={() => {
                                    dropdown?.isVisible()
                                        ? dropdown?.show()
                                        : dropdown?.hide();
                                }}
                                type="button"
                                className="flex text-sm bg-white rounded-full md:me-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600 p-2"
                                id="user-menu-button"
                                // data-dropdown-toggle="user-dropdown"
                                // data-dropdown-placement="bottom"
                            >
                                <span className="sr-only">Open user menu</span>
                                <p className="w-5 rounded-full">
                                    <strong>
                                        {authResponseDTO.iniciaisDoNome
                                            .toString()
                                            .toUpperCase()}
                                    </strong>
                                </p>
                            </button>
                            {/* <!-- Dropdown menu --> */}
                            <div
                                className={`z-50 my-4 text-base list-none bg-gray-50 divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 hidden`}
                                id="user-dropdown"
                            >
                                <div className="px-4 py-3">
                                    <span className="block text-sm text-gray-900 dark:text-white">
                                        {authResponseDTO.nomeSobrenome}
                                    </span>
                                    <span className="block text-sm text-gray-500 truncate dark:text-gray-400">
                                        {authResponseDTO.email}
                                    </span>
                                </div>
                                <ul
                                    className="py-2"
                                    //aria-labelledby="user-menu-button"
                                >
                                    <li className="hidden">
                                        <a
                                            href="#"
                                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                                        >
                                            Métricas
                                        </a>
                                    </li>
                                    <li onClick={() => setIsModalOpen(true)}>
                                        <a
                                            href="#"
                                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                                        >
                                            <FontAwesomeIcon
                                                icon={
                                                    authResponseDTO.funcao ===
                                                    "RECEPCIONISTA"
                                                        ? faUser
                                                        : faUserDoctor
                                                }
                                                className="me-2"
                                            />{" "}
                                            Editar perfil
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="block cursor-not-allowed px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                                            disabled={true}
                                        >
                                            <FontAwesomeIcon
                                                icon={faGear}
                                                className="me-2"
                                            />{" "}
                                            Configurações
                                        </a>
                                    </li>
                                </ul>
                                <div
                                    className="py-2"
                                    onClick={zerarLocalStorage}
                                >
                                    <a
                                        href="#"
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                                    >
                                        <FontAwesomeIcon
                                            icon={faPowerOff}
                                            className="me-2"
                                        />{" "}
                                        Sair
                                    </a>
                                </div>
                            </div>
                            <button
                                onClick={() => {
                                    navbar?._visible
                                        ? navbar?.expand()
                                        : navbar?.collapse();
                                }}
                                id="navbar-user-button"
                                type="button"
                                className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                            >
                                {/* <span className="sr-only">Open main menu</span> */}
                                <svg
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 17 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M1 1h15M1 7h15M1 13h15"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div
                            className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
                            id="navbar-user"
                        >
                            <ul className="font-medium flex flex-col p-2 md:p-0 mt-4 md:flex-row md:space-x-8 md:mt-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                                <li>
                                    <button
                                        onClick={() =>
                                            (window.location.href = "/agenda")
                                        }
                                        className="block py-2 pl-3 pr-4 font-medium text-white dark:text-blue-500 hover:underline decoration-none"
                                    >
                                        Agenda
                                    </button>
                                </li>
                                <li>
                                    <button
                                        onClick={() =>
                                            (window.location.href =
                                                "/pacientes")
                                        }
                                        className="block py-2 pl-3 pr-4 font-medium text-white dark:text-blue-500 hover:underline"
                                    >
                                        Pacientes
                                    </button>
                                </li>
                                {authResponseDTO.funcao !== "RECEPCIONISTA" && (
                                    <li>
                                        <button
                                            onClick={() =>
                                                (window.location.href =
                                                    "/graficos")
                                            }
                                            className="block py-2 pl-3 pr-4 font-medium text-white dark:text-blue-500 hover:underline"
                                        >
                                            Gráficos
                                        </button>
                                    </li>
                                )}
                                {/* {authResponseDTO.funcao === "RECEPCIONISTA" && (
                                     <li>
                                         <button
                                             onClick={() =>
                                                 (window.location.href =
                                                     "/utilitarios")
                                             }
                                             className="block py-2 pl-3 pr-4 font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                         >
                                             Utilitários
                                         </button>
                                     </li>
                                 )} */}
                                {/* <li>
                             <a href="/configuracao" className="block py-2 pl-3 pr-4 font-medium text-blue-600 dark:text-blue-500 hover:underline">Configuração</a>
                         </li> */}
                            </ul>
                        </div>
                    </div>
                </nav>
            )}
            <PerfilFuncionarioModal
                token={token}
                isModalOpen={isModalOpen}
                onCloseModal={() => setIsModalOpen(false)}
                authResponseDTO={authResponseDTO}
            />
        </>
    );
};

export default NavBar;
