import { Month60, Month0 } from "../Months/MonthsRange";

export const MonthStart = Month0;
export const MonthEnd = Month60;

export const threePercent = [45.6, 50.0, 53.2, 55.8, 58.0, 59.9, 61.5, 62.9, 64.3, 65.6, 66.8, 68.0, 69.2, 70.3, 71.3,
	72.4, 73.3, 74.3, 75.2, 76.2, 77.0, 77.9, 78.7, 79.6, 80.3, 80.4, 81.2, 81.9, 82.6, 83.4, 84.0, 84.7, 85.4, 86.0,
	86.7, 87.3, 87.9, 88.5, 89.1, 89.7, 90.3, 90.8, 91.4, 92.0, 92.5, 93.0, 93.6, 94.1, 94.6, 95.1, 95.7, 96.2, 96.7,
	97.2, 97.6, 98.1, 98.6, 99.1, 99.6, 100.0, 100.5];

export const fifteenPercent = [47.2, 51.7, 55.0, 57.6, 59.8, 61.7, 63.4, 64.9, 66.3, 67.6, 68.9, 70.2, 71.3, 72.5, 73.6,
	74.7, 75.7, 76.7, 77.7, 78.7, 79.6, 80.5, 81.4, 82.2, 83.1, 83.2, 84.0, 84.8, 85.5, 86.3, 87.0, 87.7, 88.4, 89.1,
	89.8, 90.5, 91.1, 91.7, 92.4, 93.0, 93.6, 94.2, 94.8, 95.4, 96.0, 96.6, 97.2, 97.7, 98.3, 98.8, 99.4, 99.9, 100.4,
	101.0, 101.5, 102.0, 102.5, 103.0, 103.5, 104.0, 104.5];

export const fiftyPercent = [49.1, 53.7, 57.1, 59.8, 62.1, 64.0, 65.7, 67.3, 68.7, 70.1, 71.5, 72.8, 74.0, 75.2, 76.4,
	77.5, 78.6, 79.7, 80.7, 81.7, 82.7, 83.7, 84.6, 85.5, 86.4, 86.6, 87.4, 88.3, 89.1, 89.9, 90.7, 91.4, 92.2, 92.9,
	93.6, 94.4, 95.1, 95.7, 96.4, 97.1, 97.7, 98.4, 99.0, 99.7, 100.3, 100.9, 101.5, 102.1, 102.7, 103.3, 103.9, 104.5,
	105.0, 105.6, 106.2, 106.7, 107.3, 107.8, 108.4, 108.9, 109.4];

export const eightyFivePercent = [51.1, 55.7, 59.2, 62.0, 64.3, 66.3, 68.1, 69.7, 71.2, 72.6, 74.0, 75.4, 76.7, 77.9,
	79.2, 80.3, 81.5, 82.6, 83.7, 84.8, 85.8, 86.8, 87.8, 88.8, 89.8, 90.0, 90.9, 91.8, 92.7, 93.5, 94.3, 95.2, 95.9,
	96.7, 97.5, 98.3, 99.0, 99.7, 100.5, 101.2, 101.9, 102.6, 103.3, 103.9, 104.6, 105.3, 105.9, 106.6, 107.2, 107.8,
	108.4, 109.1, 109.7, 110.3, 110.9, 111.5, 112.1, 112.6, 113.2, 113.8, 114.4];

export const ninetySevenPercent = [52.7, 57.4, 60.9, 63.8, 66.2, 68.2, 70.0, 71.6, 73.2, 74.7, 76.1, 77.5, 78.9, 80.2,
	81.4, 82.7, 83.9, 85.0, 86.2, 87.3, 88.4, 89.4, 90.5, 91.5, 92.5, 92.8, 93.7, 94.6, 95.6, 96.4, 97.3, 98.2, 99.0,
	99.8, 100.6, 101.4, 102.2, 103.0, 103.7, 104.5, 105.2, 106.0, 106.7, 107.4, 108.1, 108.8, 109.5, 110.2, 110.8,
	111.5, 112.1, 112.8, 113.4, 114.1, 114.7, 115.3, 116.0, 116.6, 117.2, 117.8, 118.4];
