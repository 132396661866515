import React, { useState, useEffect } from "react";
import { postLogin } from "./AuthService";

const Auth = ({ isLoggedIn, setIsLoggedIn, setAuthResponseDTO }) => {
    const [formData, setFormData] = useState({
        login: "",
        password: "",
    });

    useEffect(() => {
        localStorage.clear();
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        postLogin(formData)
            .then((response) => {
                if (response) {
                    localStorage.setItem("token", response.token);
                    localStorage.setItem(
                        "usuario",
                        JSON.stringify(response.user),
                    );
                } else {
                    localStorage.clear();
                }

                window.location.href = "/agenda";
            })
            .catch((error) => {
                console.error("Erro ao acessar a API:", error);
            });
    };

    const handleChangeFormData = (e) => {
        let { name, value } = e.target;

        setFormData({ ...formData, [name]: value });
    };

    return (
        // <div className="Auth-form-container bg-gradient-to-br from-teal-400 via-teal-500 to-teal-600">
        //     <form className="Auth-form" onSubmit={handleSubmit}>
        //         <div className="Auth-form-content">
        //             <h3 className="Auth-form-title">Entrar</h3>
        //             <div className="relative z-0 w-full mb-6 group">
        //                 <input alue={formData.login/}
        //                     onChange={handleChangeFormData}
        //                     type="text"
        //                     name="login" id="login" className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
        //                 <label for="login" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1">Login</label>
        //             </div>
        //             <div className="relative z-0 w-full mb-6 group">
        //                 <input alue={formData.login/}
        //                     onChange={handleChangeFormData}
        //                     type="password"
        //                     name="password" id="password" className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
        //                 <label for="password" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1">Digite a senha</label>
        //             </div>
        //             <div className="d-grid gap-2 mt-3">
        //                 <button type="submit" className="w-full text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Entrar</button>
        //             </div>
        //         </div>
        //     </form>
        // </div>

        <section className="bg-gradient-to-br from-teal-400 via-teal-500 to-teal-600 h-[calc(100%-1rem)] min-h-full fixed left-0 right-0">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Entre com a sua conta
                        </h1>
                        <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6" action="#">
                            <div className="relative z-0 w-full mb-6 group">
                                <input
                                    value={formData.login}
                                    onChange={handleChangeFormData}
                                    type="text"
                                    name="login"
                                    id="login"
                                    className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=" "
                                    required
                                />
                                <label
                                    htmlFor="login"
                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                                >
                                    Login
                                </label>
                            </div>
                            <div className="relative z-0 w-full mb-6 group">
                                <input
                                    value={formData.password}
                                    onChange={handleChangeFormData}
                                    type="password"
                                    name="password"
                                    id="password"
                                    className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=" "
                                    required
                                />
                                <label
                                    htmlFor="password"
                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                                >
                                    Digite a senha
                                </label>
                            </div>
                            <div className="flex items-center justify-end">
                                {/* <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input
                                            id="remember"
                                            aria-describedby="remember"
                                            type="checkbox"
                                            className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                            required=""
                                        />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label
                                            for="remember"
                                            className="text-gray-500 dark:text-gray-300"
                                        >
                                            Remember me
                                        </label>
                                    </div>
                                </div> */}
                                <button
                                    onClick={() => window.location.href = "/resetarsenha"}
                                    type="button"
                                    className="text-sm font-medium text-blue-600 hover:underline decoration-none"
                                >
                                    Esqueceu a senha?
                                </button>
                            </div>
                            <div className="d-grid gap-2 mt-3">
                                {/* {" "} */}
                                <button
                                    type="submit"
                                    className="w-full text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                >
                                    Entrar
                                </button>
                                {/* {" "} */}
                            </div>
                            {/* <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                Don’t have an account yet?{" "}
                                <a
                                    href="#"
                                    className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                                >
                                    Sign up
                                </a>
                            </p> */}
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Auth;
