import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getFuncionarios } from "./FuncionarioService";

const TipoConsulta = {
    PARTICULAR: "Particular",
    CONVENIO: "Convênio",
    RETORNO: "Retorno",
};

const NovoEventoModal = ({
    dataSelecionada,
    token,
    isModalOpen,
    onCloseModal,
    pacientes,
    pacienteParaEditar,
    funcionarios,
}) => {
    const [pacienteSelecionado, setPacienteSelecionado] = useState(0);
    const [funcionarioSelecionado, setFuncionarioSelecionado] = useState(null);
    const [temPacienteParaEditar, setTemPacienteParaEditar] = useState(
        pacienteParaEditar !== null && pacienteParaEditar,
    );
    // const [funcionarios, setFuncionarios] = useState([]);
    const [observacao, setObservacao] = useState("");
    const [formData, setFormData] = useState({
        paciente: { pacienteSelecionado },
        dataDaConsulta: '',
        horaDaConsulta: "",
        funcionario: { funcionarioSelecionado },
        statusAgenda: "AGUARDANDO",
        observacao: "",
        tipoConsulta: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        

        fetch(`${process.env.REACT_APP_URL_API}/api/agenda`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify(formData),
        })
            .then((response) => {
                if (response.ok) {
                    window.location.reload();
                } else {
                    alert("Erro ao enviar dados.");
                }
            })
            .catch((error) => {
                console.error("Erro na requisição:", error);
            });
    };

    const changePacienteSelecionado = (e) => {
        
        setPacienteSelecionado(e);

        setFormData({
            ...formData,
            paciente: e.id,
        });
    };

    useEffect(() => {
        if (dataSelecionada) {
            setFormData({
                paciente: { pacienteSelecionado },
                dataDaConsulta: dataSelecionada,
                horaDaConsulta: "",
                funcionario: { funcionarioSelecionado },
                statusAgenda: "AGUARDANDO",
                observacao: "",
                tipoConsulta: "",
            });
        }
    }, [dataSelecionada]);

    const changeFuncionarioSelecionado = (e) => {
        setFuncionarioSelecionado(e);

        setFormData({
            ...formData,
            funcionario: e.id,
        });
    };

    const changeTipoConsultaSelecionada = (e) => {
        // setFuncionarioSelecionado(e);

        setFormData({
            ...formData,
            tipoConsulta: e.target.value,
        });
    };

    const changeObservacao = (e) => {
        // setFuncionarioSelecionado(e);

        setFormData({
            ...formData,
            observacao: e.target.value,
        });
    };

    return (
        <>
            {isModalOpen && (
                <div
                    id="top-right-modal"
                    data-modal-placement="top-right"
                    tabIndex="-1"
                    className="bg-black bg-opacity-50 fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] min-h-full justify-center items-start flex"
                >
                    <div className="relative w-full max-w-md max-h-full">
                        {/* <!-- Modal content --> */}
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            {/* <!-- Modal header --> */}
                            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    Adicionar na agenda
                                </h3>
                                <button
                                    onClick={onCloseModal}
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    data-modal-hide="novo-evento-modal"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <div className="p-6 space-y-6">
                                <form
                                    onSubmit={handleSubmit}
                                    id="formAgendamento"
                                >
                                    <div className="mb-6">
                                        {/* {pacienteSelecionado ? JSON.stringify(pacienteSelecionado) : '---'} */}
                                        <Select
                                            isDisabled={temPacienteParaEditar}
                                            onChange={changePacienteSelecionado}
                                            value={
                                                pacienteSelecionado ||
                                                pacienteParaEditar
                                            }
                                            maxMenuHeight={150}
                                            options={pacientes}
                                            placeholder="Selecione o paciente"
                                            required
                                        />
                                        {/* <h6 className="mb-3">Dados do paciente</h6> */}
                                    </div>
                                    <div className="grid md:grid-cols-2 md:gap-6">
                                        <div className="relative z-0 w-full mb-6 group">
                                            <input
                                                onChange={handleChange}
                                                value={formData.dataDaConsulta}
                                                type="date"
                                                name="dataDaConsulta"
                                                id="dataDaConsulta"
                                                className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                placeholder="DD/MM/AAAA"
                                                required
                                            />
                                            <label
                                                for="dataDaConsulta"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                                            >
                                                Data da consulta
                                            </label>
                                        </div>
                                        <div className="relative z-0 w-full mb-6 group">
                                            <input
                                                onChange={handleChange}
                                                value={formData.horaDaConsulta}
                                                type="time"
                                                name="horaDaConsulta"
                                                id="horaDaConsulta"
                                                className="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                placeholder=" "
                                                required
                                            />
                                            <label
                                                for="horaDaConsulta"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1"
                                            >
                                                Hora da consulta
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mb-6">
                                        {/* {pacienteSelecionado ? JSON.stringify(pacienteSelecionado) : '---'} */}
                                        <Select
                                            onChange={
                                                changeFuncionarioSelecionado
                                            }
                                            value={funcionarioSelecionado}
                                            maxMenuHeight={150}
                                            options={funcionarios}
                                            placeholder="Selecione o médico"
                                            required
                                        />
                                        {/* <h6 className="mb-3">Dados do paciente</h6> */}
                                    </div>
                                    <div className="mb-6">
                                        <label
                                            for="tipoConsulta"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Tipo da consulta:
                                        </label>

                                        <select
                                            disabled={
                                                funcionarioSelecionado === null
                                            }
                                            id="tipoConsulta"
                                            name="tipoConsulta"
                                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            required
                                            onChange={
                                                changeTipoConsultaSelecionada
                                            }
                                            value={formData.tipoConsulta}
                                        >
                                            {/* <option value="">Tipo de consulta</option> */}
                                            {/* <option value="particular">Particular</option>
                                                    <option value="convenio">Convênio</option>
                                                    <option value="retorno">Retorno</option> */}
                                            <option value="">
                                                Tipo da consulta
                                            </option>
                                            {Object.keys(TipoConsulta).map(
                                                (chave, index) => (
                                                    <option
                                                        key={index}
                                                        value={chave}
                                                    >
                                                        {TipoConsulta[chave]}
                                                    </option>
                                                ),
                                            )}
                                        </select>
                                    </div>
                                    <div className="mb-6">
                                        <label
                                            for="observacoes"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Observações
                                        </label>
                                        <textarea
                                            onChange={changeObservacao}
                                            value={formData.observacao}
                                            name="observacoes"
                                            id="observacoes"
                                            rows="4"
                                            className="no-resize mb-5 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Digite uma observação..."
                                        ></textarea>
                                    </div>
                                </form>
                            </div>
                            <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                <button
                                    onClick={onCloseModal}
                                    type="button"
                                    className="text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                    data-modal-hide="novo-evento-modal"
                                >
                                    Fechar
                                </button>
                                {/* {isEditar && ( */}
                                <button
                                    type="submit"
                                    form="formAgendamento"
                                    className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                >
                                    Salvar
                                </button>
                                {/* // )} */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default NovoEventoModal;
