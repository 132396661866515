import React, { useState, useEffect } from "react";
import { redefinirSenha, validarToken } from "./AuthService";

const FormRedefinirSenha = () => {
    const [senhasSaoDiferentes, setSenhasSaoDiferentes] = useState(true);
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState(null);

    const validarSeSenhasSaoIguais = (e) => {
        setConfirmNewPassword(e.target.value);
        setSenhasSaoDiferentes(e.target.value !== newPassword);
    };

    useEffect(() => {
        validarToken(window.location.pathname);
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        if (senhasSaoDiferentes) {
            alert("As senhas informadas devem ser iguais!")
        } else {
            redefinirSenha(window.location.pathname, newPassword);
        }
    }

    return (
        <section className="bg-gradient-to-br from-teal-400 via-teal-500 to-teal-600">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Alterar a senha
                        </h1>
                        <span className="text-sm">
                            Informe abaixo sua nova senha.
                        </span>
                        <form
                            className="max-w-sm mx-auto"
                            onSubmit={handleSubmit}
                        >
                            <label
                                for="username-email-input"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Informe sua senha *
                            </label>
                            <div className="relative mb-3">
                                <input
                                    value={newPassword}
                                    type="password"
                                    onChange={(e) =>
                                        setNewPassword(e.target.value)
                                    }
                                    id="username-email-input"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Informe sua nova senha"
                                    required
                                />
                            </div>
                            <label
                                for="username-email-input"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Confirmação da senha *
                            </label>
                            <div className="relative">
                                <input
                                    value={confirmNewPassword}
                                    type="password"
                                    onInput={validarSeSenhasSaoIguais}
                                    id="username-email-input"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Confirme sua nova senha"
                                    required
                                />
                                <p
                                    className={`mt-2 text-sm font-medium ${
                                        confirmNewPassword &&
                                        senhasSaoDiferentes
                                            ? "text-red-600"
                                            : "hidden"
                                    }`}
                                >
                                    {"A senhas devem ser iguais!"}
                                </p>
                            </div>
                            <div className="d-grid gap-2 mt-3">
                                <button
                                    type="submit"
                                    className="w-full text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                >
                                    Enviar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FormRedefinirSenha;
