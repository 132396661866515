import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import NovoEventoModal from "./NovoEventoModal";
import listPlugin from "@fullcalendar/list";
import FichaDeAtendimentoModal from "./FichaDeAtendimentoModal";
import { getTodosPacientes } from "./PacientesService";
import { getAllAgendamentosByUserIdETipo } from "./AgendaService";
import { getFuncionarios } from "./FuncionarioService";
import {
    getConsultasPorIdPaciente,
    getExamesFisicosPorIdPaciente,
} from "./ConsultaService";
import EditarEventoModal from "./EditarEventoModal";
import Select from "react-select";
import ConsultasFinalizadasModal from "./ConsultasFinalizadasModal";
import AgendaPorMedicoModal from "./AgendaPorMedicoModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarDay,
    faCalendarDays,
    faChartLine,
} from "@fortawesome/free-solid-svg-icons";

const Agenda = ({ authResponseDTO, token }) => {
    const [modalNovoAtendimentoOpen, setModalNovoAtendimentoOpen] =
        useState(false);
    const [modalEditarAtendimentoOpen, setModalEditarAtendimentoOpen] =
        useState(false);
    const [
        modalFichaDeAtendimentoModalOpen,
        setModalFichaDeAtendimentoModalOpen,
    ] = useState(false);
    const [modalAgendaPorMedico, setModalAgendaPorMedico] = useState(false);
    const [pacientes, setPacientes] = useState([]);
    const [pacienteSelecionadoNaAgenda, setPacienteSelecionadoNaAgenda] =
        useState(null);
    const [events, setEvents] = useState([]);
    const [funcionarios, setFuncionarios] = useState([]);
    const [consultasPorIdPaciente, setConsultasPorIdPaciente] = useState([]);
    const [
        consultasParaGraficosPorIdPaciente,
        setConsultasParaGraficosPorIdPaciente,
    ] = useState([]);
    const [selectDate, setSelectDate] = useState(null);
    const [statusAgenda, setStatusAgenda] = useState("");
    const [idAgenda, setIdAgenda] = useState("");
    const [funcionarioDoEventoSelecionado, setFuncionarioDoEventoSelecionado] =
        useState(null);
    const [tipoConsulta, setTipoConsulta] = useState("");
    const [observacao, setObservacao] = useState("");
    const [eventInfo, setEventInfo] = useState([]);
    const [isEditavel, setIsEdtitavel] = useState(false);
    const [titulo, setTitulo] = useState("");
    const [funcionarioSelecionado, setFuncionarioSelecionado] = useState("");
    const [modalConsultasFinalizadasOpen, setModalConsultasFinalizadasOpen] =
        useState(false);
    const [headerToolBarLeft, setHeaderToolBarLeft] = useState("");
    const [customButton, setCustomButton] = useState({});
    const [dataSelecionada, setDataSelecionada] = useState("");

    const openModalNovoAtendimento = (info) => {
        setTitulo("Adicionar na agenda");
        setDataSelecionada(info.dateStr);
        setEventInfo([]);
        setIsEdtitavel(false);
        setModalNovoAtendimentoOpen(true);
    };

    const openModalConsultasFinalizadas = (info) => {
        setModalConsultasFinalizadasOpen(true);
    };

    const openModalAgendaPorMedico = (info) => {
        setModalAgendaPorMedico(true);
    };

    useEffect(() => {
        getTodosPacientes(token)
            .then((response) => {
                setPacientes(response);
            })
            .catch((error) => {
                console.error("Erro ao acessar a API:", error);
            });
    }, []);

    useEffect(() => {
        const fazerRequisicao = () => {
            getAllAgendamentosByUserIdETipo(
                token,
                authResponseDTO.id,
                authResponseDTO.funcao,
            )
                .then((response) => {
                    setEvents(response);
                })
                .catch((error) => {
                    console.error("Erro ao acessar a API:", error);
                });
        };

        fazerRequisicao();
        const intervalo = setInterval(fazerRequisicao, 30000);

        return () => {
            clearInterval(intervalo);
        };
    }, []);

    useEffect(() => {
        getFuncionarios(token)
            .then((response) => {
                setFuncionarios(response);
            })
            .catch((error) => {
                console.error("Erro ao acessar a API:", error);
            });
    }, []);

    useEffect(() => {
        if (authResponseDTO.funcao !== "RECEPCIONISTA") {
            setCustomButton({
                myCustomButton: {
                    text: "+ Novo",
                    click: openModalNovoAtendimento,
                },
                consultasFinalizadas: {
                    text: "Finalizadas",
                    click: openModalConsultasFinalizadas,
                },
            });

            setHeaderToolBarLeft(
                window.innerWidth < 640
                    ? ""
                    : "timeGridDay,timeGridWeek,dayGridMonth,listMonth consultasFinalizadas",
            );
        } else {
            setCustomButton({
                myCustomButton: {
                    text: "+ Novo",
                    click: openModalNovoAtendimento,
                },
                consultasPorMedico: {
                    text: "Filtrar por médico",
                    click: openModalAgendaPorMedico,
                },
                consultasFinalizadas: {
                    text: "Finalizadas",
                    click: openModalConsultasFinalizadas,
                },
            });

            setHeaderToolBarLeft(
                window.innerWidth < 640
                    ? ""
                    : "timeGridDay,timeGridWeek,dayGridMonth,listMonth consultasPorMedico,consultasFinalizadas",
            );
        }
    }, []);

    const closeModal = () => {
        setModalNovoAtendimentoOpen(false);
    };

    const closeModalFichaDeAtendimentoModalOpen = () => {
        setModalFichaDeAtendimentoModalOpen(false);
    };

    const setConsultaSelecionada = (idPaciente) => {
        getExamesFisicosPorIdPaciente(idPaciente, token)
            .then((response) => {
                setConsultasParaGraficosPorIdPaciente(response);
            })
            .catch((error) => {
                setConsultasParaGraficosPorIdPaciente(null);
                console.error("Erro ao acessar a API:", error);
            });

        getConsultasPorIdPaciente(idPaciente, token)
            .then((response) => {
                setConsultasPorIdPaciente(response);
            })
            .catch((error) => {
                console.error("Erro ao acessar a API:", error);
            });
    };

    const defaultView = window.innerWidth < 640 ? "listMonth" : "dayGridMonth";

    const handleSelectChange = (e) => {
        setFuncionarioSelecionado(e);

        getAllAgendamentosByUserIdETipo(
            token,
            funcionarioSelecionado.id,
            funcionarioSelecionado.funcao,
        )
            .then((response) => {
                setEvents(response);
            })
            .catch((error) => {
                console.error("Erro ao acessar a API:", error);
            });
    };

    const abrirModalParaEditarAtendimento = (info) => {
        alert("Deseja editar?");
        setEventInfo(info);
        setModalEditarAtendimentoOpen(true);
    };

    return (
        <>
            <section className="bg-gray-900 dark:bg-gray-900 p-3 sm:p-5 w-full h-full">
                <div className="mx-auto px-4 lg:px-12">
                    <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                        <h1 className="text-3xl font-bold text-gray-900 ml-5 mt-5">
                            <FontAwesomeIcon
                                className="mr-1"
                                icon={faCalendarDays}
                            />{" "}
                            Agenda
                        </h1>
                        <div className="p-3">
                            <FullCalendar
                                height={600}
                                locale={ptBrLocale}
                                eventColor={"green"}
                                plugins={[
                                    dayGridPlugin,
                                    interactionPlugin,
                                    timeGridPlugin,
                                    listPlugin,
                                ]}
                                initialView={defaultView}
                                weekends={true}
                                events={events}
                                eventClick={(info) => {
                                    if (
                                        "RECEPCIONISTA".includes(
                                            authResponseDTO.funcao,
                                        )
                                    ) {
                                        abrirModalParaEditarAtendimento(info);
                                        return;
                                    }

                                    if (
                                        window.confirm(
                                            `Gostaria de iniciar o atendimento para ${info.event.title}?`,
                                        )
                                    ) {
                                        setObservacao(
                                            info.event.extendedProps.observacao,
                                        );
                                        setTipoConsulta(
                                            info.event.extendedProps
                                                .tipoConsulta,
                                        );
                                        setFuncionarioDoEventoSelecionado(
                                            info.event.extendedProps.usuario,
                                        );
                                        setStatusAgenda(
                                            info.event.extendedProps
                                                .statusAgenda,
                                        );
                                        setIdAgenda(info.event.id);
                                        setModalFichaDeAtendimentoModalOpen(
                                            true,
                                        );
                                        setPacienteSelecionadoNaAgenda(
                                            info.event.extendedProps.paciente,
                                        );
                                        setConsultaSelecionada(
                                            info.event.extendedProps.paciente
                                                .id,
                                        );
                                    }
                                }}
                                dateClick={(info) => {
                                    openModalNovoAtendimento(info);
                                }}
                                headerToolbar={{
                                    left: headerToolBarLeft,
                                    center: "title",
                                    right: "myCustomButton,prev,next",
                                }}
                                selectable={true}
                                timeZone="America/Sao_Paulo"
                                dayMaxEventRows={2}
                                titleFormat={{
                                    year: "numeric",
                                    month: "short",
                                }}
                                customButtons={customButton}
                            />
                        </div>
                        <AgendaPorMedicoModal
                            events={events}
                            isModalOpen={modalAgendaPorMedico}
                            onCloseModal={() => setModalAgendaPorMedico(false)}
                            funcionarios={funcionarios}
                            abrirModalParaEditarAtendimento={
                                abrirModalParaEditarAtendimento
                            }
                        />
                        <ConsultasFinalizadasModal
                            funcionarios={funcionarios}
                            token={token}
                            authResponseDTO={authResponseDTO}
                            isModalOpen={modalConsultasFinalizadasOpen}
                            onCloseModal={() =>
                                setModalConsultasFinalizadasOpen(false)
                            }
                        />
                        <NovoEventoModal
                            titulo={titulo}
                            isEditando={isEditavel}
                            eventInfo={eventInfo}
                            token={token}
                            isModalOpen={modalNovoAtendimentoOpen}
                            onCloseModal={closeModal}
                            pacientes={pacientes}
                            funcionarios={funcionarios}
                            dataSelecionada={dataSelecionada}
                        />
                        <EditarEventoModal
                            eventInfo={eventInfo}
                            token={token}
                            isModalOpen={modalEditarAtendimentoOpen}
                            onCloseModal={() =>
                                setModalEditarAtendimentoOpen(false)
                            }
                            pacientes={pacientes}
                            funcionarios={funcionarios}
                        />
                        <FichaDeAtendimentoModal
                            observacao={observacao}
                            tipoConsulta={tipoConsulta}
                            idAgenda={idAgenda}
                            statusAgenda={statusAgenda}
                            token={token}
                            authResponseDTO={authResponseDTO}
                            funcionarioDoEventoSelecionado={
                                funcionarioDoEventoSelecionado
                            }
                            consultasPorIdPaciente={consultasPorIdPaciente}
                            paciente={pacienteSelecionadoNaAgenda}
                            isModalOpen={modalFichaDeAtendimentoModalOpen}
                            onCloseModal={closeModalFichaDeAtendimentoModalOpen}
                            consultasParaGraficosPorIdPaciente={
                                consultasParaGraficosPorIdPaciente
                            }
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default Agenda;
