export const alterarStatusAgendamento = async (idAgendamento, token, statusAgenda) => {
    await fetch(`${process.env.REACT_APP_URL_API}/api/agenda/${idAgendamento}/status`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: `{
            "statusAgenda": "${statusAgenda}"
        }`,
    });
};

export const getAllAgendamentosByUserIdETipo = async (token, idUsuario, funcao) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
        }
    };

    const response = await fetch(`${process.env.REACT_APP_URL_API}/api/agenda/usuario/${idUsuario}/${funcao}`, options)

    return await response.json();
}

export const getConsultasFinalizadasPorIdUsuarioEData = async (token, idUsuario, dataDaConsulta) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
        }
    };

    const response = await fetch(`${process.env.REACT_APP_URL_API}/api/agenda/${idUsuario}/${dataDaConsulta}`, options)

    return await response.json();
}