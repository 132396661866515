import React from "react";

const ListaConsultasFinalizadas = ({ consultasFinalizadas }) => {
    if (
        !consultasFinalizadas.agendas ||
        consultasFinalizadas.agendas.length <= 0
    ) {
        return (
            <>
                <p className="mb-3 text-center text-gray-500 dark:text-gray-400">
                    Não há resultados
                </p>
            </>
        );
    }

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
    };

    return (
        <>
            <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Paciente
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Consulta
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Hora da consulta
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {consultasFinalizadas.agendas.map(
                            (consultaFinalizada, index) => {
                                return (
                                    <>
                                        <tr
                                            key={index}
                                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                        >
                                            <th
                                                scope="row"
                                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                                {
                                                    consultaFinalizada.paciente
                                                        .nome
                                                }
                                            </th>
                                            <td className="px-6 py-4">
                                                {
                                                    consultaFinalizada.tipoConsulta
                                                }
                                            </td>
                                            <td className="px-6 py-4">
                                                {
                                                    consultaFinalizada.horaDaConsulta
                                                }
                                            </td>
                                        </tr>
                                    </>
                                );
                            },
                        )}
                    </tbody>
                </table>
            </div>
            <span className="font-semibold text-gray-900 dark:text-white">
                Total convênio: {consultasFinalizadas.convenio}
            </span>
            <br />
            <span className="font-semibold text-gray-900 dark:text-white">
                Total particular: {consultasFinalizadas.particular}
            </span>
            <br />
            <span className="font-semibold text-gray-900 dark:text-white">
                Total retorno: {consultasFinalizadas.retorno}
            </span>
            <br />
            <span className="font-semibold text-gray-900 dark:text-white">
                Total: {consultasFinalizadas.agendas.length}
            </span>
        </>
    );
};

export default ListaConsultasFinalizadas;
