// import "bootstrap/dist/css/bootstrap.min.css"
// import React, { useState, useEffect } from 'react';
import "./App.css"
// import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import Auth from "./Auth"
import Agenda from "./Agenda"
import Pacientes from "./Pacientes"
import NavBar from './NavBar';
// import Graficos from "./pages/Graficos"
// import Configuracao from "./pages/Configuracao"

// function App() {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [authResponseDTO, setAuthResponseDTO] = useState(null);

//   useEffect(() => {
//     debugger;
//     const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
//     if (storedIsLoggedIn === "true") {
//       setIsLoggedIn(true);
//     }
//   }, []);

//   useEffect(() => {
//     localStorage.setItem('isLoggedIn', isLoggedIn);
//   }, [isLoggedIn]);

//   return (
//     <Router>
//       {isLoggedIn && (<NavBar authResponseDTO={authResponseDTO} />)}
//       <Routes>
//         <Route path="login" element={<Auth isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} setAuthResponseDTO={setAuthResponseDTO} />} />
//         <Route
//           path="agenda"
//           // beforeunload={() => "Você tem alterações não salvas. Deseja sair?"}
//           element={isLoggedIn ? <Agenda authResponseDTO={authResponseDTO} /> : <Navigate to="/login" />}
//         />
//         {/* <Route
//             path="pacientes"
//             element={isLoggedIn ? <Pacientes authResponseDTO={authResponseDTO} /> : <Navigate to="/login" />}
//           /> */}
//       </Routes>
//     </Router>
//   )
// }

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Configuracao from "./Configuracao";
import Graficos from "./Graficos";
import Utilitarios from "./Utilitarios";
import ResetPassword from "./ResetPassword";
import FormRedefinirSenha from "./FormRedefinirSenha";
import { useEffect, useState } from "react";

function App() {
  const token = localStorage.getItem('token');
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const authResponseDTO = localStorage.getItem('usuario');

  useEffect(() => {
    setIsAuthenticated(!!localStorage.getItem('token'));
  }, [])

  return (
    <>
      <BrowserRouter>
        {isAuthenticated && (<NavBar authResponseDTO={JSON.parse(authResponseDTO)} token={token} />)}
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Auth />} />
          <Route path="/resetarsenha" element={<ResetPassword />} />
          <Route path="/redefinir/*" element={<FormRedefinirSenha />} />
          <Route
            path="/agenda"
            element={
              isAuthenticated ?
                <Agenda authResponseDTO={JSON.parse(authResponseDTO)} token={token} />
                :
                <Navigate to="/login" />
            }
          />
          <Route
            path="/pacientes"
            element={
              isAuthenticated ?
                <Pacientes authResponseDTO={JSON.parse(authResponseDTO)} token={token} />
                :
                <Navigate to="/login" />
            }
          />
          <Route
            path="/graficos"
            element={
              isAuthenticated ?
                <Graficos
                  consultasDoPaciente={
                    null
                  }
                  permiteSelecionarPaciente={
                    true
                  }
                  paciente={null}
                  authResponseDTO={
                    JSON.parse(authResponseDTO)
                  }
                  token={token}
                  altura={""}
                  peso={""}
                  perimetroCefalico={""}
                  isModalDeAtendimento={false}
                />
                :
                <Navigate to="/login" />
            }
          />
          {/* <Route
            path="/utilitarios"
            element={
              isAuthenticated ?
                <Utilitarios
                  authResponseDTO={
                    JSON.parse(authResponseDTO)
                  }
                  token={token}
                />
                :
                <Navigate to="/login" />
            }
          /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App
