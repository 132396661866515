import React from "react";
import FormCriacaoPaciente from "./FormCriacaoPaciente";
import TimeLinePaciente from "./TimeLinePaciente";
import { getConsultasPorIdPaciente } from "./ConsultaService";

const ConfiguracaoPacienteModal = ({
    authResponseDTO,
    consultasPorIdPaciente,
    setConsultasPorIdPaciente,
    isButtonStatic,
    setIsButtonStatic,
    token,
    onModalClose,
    modalOpen,
    titulo,
    isEditar,
    paciente,
    exibeHistorico,
    subTituloModal,
}) => {
    const carregarHistoricos = async () => {
        setIsButtonStatic(false);

        await getConsultasPorIdPaciente(paciente.id, token)
            .then((response) => {
                if (response && response.length === 0) {
                    alert("Paciente ainda não possui histórico para exibir");
                }

                setConsultasPorIdPaciente(response);
            })
            .catch((error) => {
                alert("Erro ao acessar a API:", error);
            });

        setIsButtonStatic(true);
    };

    return (
        <>
            {modalOpen && (
                <div
                    id="top-right-modal"
                    data-modal-placement="top-right"
                    tabIndex="-1"
                    className="bg-black bg-opacity-50 fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] min-h-full justify-center items-start flex"
                >
                    <div className="relative w-full max-w-2xl max-h-full">
                        {/* <!-- Modal content --> */}
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            {/* <!-- Modal header --> */}
                            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    {titulo}
                                </h3>
                                <button
                                    onClick={onModalClose}
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            {/* <!-- Modal body --> */}
                            <div className="p-6 space-y-6">
                                <FormCriacaoPaciente
                                    isEditar={isEditar}
                                    token={token}
                                    informacoesPaciente={paciente}
                                    temInformacaoDosPacientes={
                                        paciente !== null || paciente
                                    }
                                />

                                {/* {subTituloModal !== "" && (
                                    <span className="text-red-800">
                                        **Data da última consulta:{" "}
                                        {subTituloModal}
                                    </span>
                                )} */}

                                {(paciente !== null || paciente) &&
                                    // authResponseDTO.funcao !==
                                    //     "RECEPCIONISTA" &&
                                    exibeHistorico && (
                                        <>
                                            <div className="justify-between items-start flex w-full">
                                                <h1
                                                    style={{
                                                        alignSelf: "center",
                                                    }}
                                                >
                                                    Histórico de consultas
                                                </h1>

                                                {!isButtonStatic && (
                                                    <button
                                                        style={{
                                                            alignSelf: "center",
                                                        }}
                                                        disabled
                                                        type="button"
                                                        className="py-2 px-3 text-xs font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                                                    >
                                                        <svg
                                                            aria-hidden="true"
                                                            role="status"
                                                            className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                                                            viewBox="0 0 100 101"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                fill="currentColor"
                                                            />
                                                            <path
                                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                fill="#1C64F2"
                                                            />
                                                        </svg>
                                                    </button>
                                                )}
                                                {isButtonStatic && (
                                                    <button
                                                        onClick={
                                                            carregarHistoricos
                                                        }
                                                        style={{
                                                            alignSelf: "center",
                                                        }}
                                                        type="button"
                                                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                                    >
                                                        Carregar históricos
                                                    </button>
                                                )}
                                            </div>

                                            <TimeLinePaciente
                                                consultasPorIdPaciente={
                                                    consultasPorIdPaciente
                                                }
                                                authResponseDTO={authResponseDTO}
                                                isCopiarDados={false}
                                            />
                                        </>
                                    )}
                            </div>
                            {/* <!-- Modal footer --> */}
                            <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                <button
                                    onClick={onModalClose}
                                    type="button"
                                    className="text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                >
                                    Fechar
                                </button>
                                <button
                                    type="submit"
                                    form="formModalPaciente"
                                    className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                >
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ConfiguracaoPacienteModal;
