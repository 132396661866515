import React from "react";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { CHART_COLORS } from "../../styled";

const AlturaPorIdade = ({
    idadeEmMeses,
    nomeDoPaciente,
    valorPacienteSelecionado,
    defaultValue,
    alturaAtual,
}) => {
    const labels = [];

    for (var i = 0; i <= 60; i++) {
        labels.push(i);
    }

    if (
        valorPacienteSelecionado &&
        valorPacienteSelecionado !== null &&
        alturaAtual?.length > 0
    ) {
        
        valorPacienteSelecionado = valorPacienteSelecionado.concat({
            x: idadeEmMeses,
            y: alturaAtual,
        });
    }

    const data = {
        labels: labels,
        datasets: [
            {
                label: nomeDoPaciente,
                data: valorPacienteSelecionado, //[{x: 1, y: 30}, {x: 1, y: 20}, {x: 1, y: 10}],
                backgroundColor: CHART_COLORS.purple,
                axis: "y",
                borderColor: CHART_COLORS.purple,
                pointStyle: "circle",
                pointRadius: 7,
            },
            {
                label: "3%",
                data: defaultValue.threePercent,
                backgroundColor: CHART_COLORS.red,
                borderColor: CHART_COLORS.red,
            },
            {
                label: "15%",
                data: defaultValue.fifteenPercent,
                backgroundColor: CHART_COLORS.yellow,
                borderColor: CHART_COLORS.yellow,
            },
            {
                label: "50%",
                data: defaultValue.fiftyPercent,
                backgroundColor: CHART_COLORS.green,
                borderColor: CHART_COLORS.green,
            },
            {
                label: "85%",
                data: defaultValue.eightyFivePercent,
                backgroundColor: CHART_COLORS.yellow,
                borderColor: CHART_COLORS.yellow,
            },
            {
                label: "97%",
                data: defaultValue.ninetySevenPercent,
                backgroundColor: CHART_COLORS.red,
                borderColor: CHART_COLORS.red,
            },
        ],
    };

    // if (valorPacienteSelecionado && valorPacienteSelecionado !== null) {
    
    // data.datasets.unshift({
    //     label: "---",
    //     data: valorPacienteSelecionado,
    //     backgroundColor: CHART_COLORS.purple,
    //     borderColor: CHART_COLORS.purple,
    //     pointStyle: "circle",
    //     pointRadius: 7,
    // });
    // }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: "Altura (cm)",
                position: "left",
                font: {
                    size: 12,
                    weight: "bold",
                },
            },
            subtitle: {
                display: true,
                text: "Idade (meses)",
                position: "bottom",
                font: {
                    size: 12,
                    weight: "bold",
                },
            },
        },
        pointStyle: "circle",
        pointRadius: 4,
        scales: {
            x: {
                ticks: {
                    autoSkip: false,
                },
            },
        },
    };

    return (
        <div className={valorPacienteSelecionado?.length > 0 ? "" : "disabled"}>
            <h1 className="text-xl text-center">Altura por idade</h1>
            <h2 className="mb-3 text-gray-500 text-center">
                0 até 5 anos (percentil)
            </h2>
            <Line data={data} options={options} />
        </div>
    );
};

export default AlturaPorIdade;
