import React from "react";
import OpcoesPacientes from "./OpcoesPacientes";

const Pacientes = ({ authResponseDTO, token }) => {
    return (
        <>
            <OpcoesPacientes authResponseDTO={authResponseDTO} token={token} />
        </>
    );
};

export default Pacientes;
