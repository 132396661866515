import React from "react";
import NavBar from "./NavBar";

function Configuracao() {
    return (
        <>
            {/* <NavBar /> */}
            <h1>Configuração</h1>
        </>
    );
}

export default Configuracao;