export const getConsultas = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_URL_API}/api/consulta`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
    });

    return await response.json();
};

export const getExamesFisicosPorIdPaciente = async (idPaciente, token) => {
    const response = await fetch(`${process.env.REACT_APP_URL_API}/api/consulta/${idPaciente}/exameFisicoGrafico`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
    });

    return await response.json();
};

export const getDataUltimaConsultaPorIdPaciente = async (idPaciente, token) => {
    const response = await fetch(`${process.env.REACT_APP_URL_API}/api/consulta/dataUltimaConsulta/${idPaciente}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
    });

    return await response.text();
};

export const getConsultasPorIdPaciente = async (idPaciente, token) => {
    const response = await fetch(`${process.env.REACT_APP_URL_API}/api/consulta/${idPaciente}/historicos`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
    });

    return await response.json();
};

export const postConsulta = async (formData, token) => {
    await fetch(`${process.env.REACT_APP_URL_API}/api/consulta`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': token
        },
        body: JSON.stringify(formData)
    }).then(response => {
        if (response.ok) {
            window.location.reload();
        } else {
            alert("Erro ao enviar dados.");
        }
    }).catch(error => {
        console.error("Erro na requisição:", error);
    });
}